import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {useTranslation} from "react-i18next";
import {DatePicker, Grid, List, Table, TableProps, Tooltip} from "antd";
import invoice from "../../../types/invoice";
import CommonListFilter from "../../../types/CommonListFilter";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import {subscribe, unsubscribe} from "../../../CommonComponents/Notifications/NotificationContext";
import {InvoiceStatusColor, ServerdateToPoints, ToMoney} from "../../../utils/helpers";
import {ColumnsType} from "antd/es/table";
import {DownloadOutlined, UploadOutlined} from "@ant-design/icons";
import axiosInstance from "../../../utils/axiosInstance";



const mapEnumToString=(i : number)=>{
    switch ( i ) {
        case 0:
            return "Drugačen dokument"
        case 10:
            return "Račun"
        default:
            return "Drugačen dokument"
    }
}

const ReceivedDocsTable =()=>{

    const history= useHistory();
    const user = AuthService.getCurrentUser();

    if(!user){
        history.push("/login");
    }
    const {t} = useTranslation();


    const { useBreakpoint } = Grid;
    const breakpoints = useBreakpoint();
    

    const [userOrganisation, setuserOrganisation] = useState(AuthService.getCurrentOrganisation());

    const [docs, setdocs] = useState<invoice[]|null>(null)

    const[pageloading, setpageloading] = useState<boolean>(false)
    const[pageSize, setPageSize] = useState(10)
    const[totalResults, setTotalResults] = useState(10)
    const[currentPage, setCurrentPage] = useState(1)


    const fetchReceivedInvoices = async (id: string, currentPage: number|undefined, pageSize: number|undefined): Promise<invoice[]> => {
        try{
            const response = await axiosInstance.get(AuthService.DATA_URL+'document-inbox/'+userOrganisation.parentId
                +"?page="+(currentPage? currentPage : 1) +(pageSize? "&pageSize="+pageSize : "")
                +"&childId="+userOrganisation.id
               // +( _filter.dateFrom? "&fromDate="+(_filter.dateFrom) : "" )
               // +( _filter.dateTo? "&toDate="+(_filter.dateTo) : "" )
                , {headers: authHeader()});
            console.log("fetchReceivedInvoices: ",response.data)
            //setTotalResults(response.data.pagination.totalResults);
            
            
            console.log("ressss:" , response.data.map((invoice: any) => ({
                integrationSpecificId: invoice._id,
                documentReference: invoice.prediction.invoiceId ? invoice.prediction.invoiceId?.content : "",
                amount: invoice.prediction.invoiceTotal.value.amount,
                dateIssued: invoice.prediction.invoiceDate?.value,
                dateDue: invoice.prediction.dueDate?.value,
                customer: {
                    name : invoice.prediction.vendorName?.content,
                    //taxNumber: invoice.prediction.vendorTaxId.content,
                    //address:{street: invoice.prediction.vendorAddress.content},
                    
                },
                sentOrReceived: 'received'
            })))

            setdocs(response.data.map((invoice: any) => ({
                integrationSpecificId: invoice._id,
                documentReference: invoice.prediction.invoiceId?.content,
                amount: invoice.prediction.invoiceTotal.value?.amount,
                dateIssued: invoice.prediction.invoiceDate?.value,
                dateDue: invoice.prediction.dueDate?.value,
                status: invoice.integrationSendStatus,
                customer: {
                    name : invoice.prediction.vendorName?.content,
                    taxNumber: invoice.prediction.vendorTaxId ? invoice.prediction.vendorTaxId.content : "",
                    address:{street: invoice.prediction.vendorAddress?.content},

                },
                sentOrReceived: 'received',
                key: invoice._id
            })));
            
            return response.data.map((invoice: any) => ({
                customer: {
                    //name : invoice.prediction.vendorName.content,
                    //taxNumber: invoice.prediction.vendorTaxId.content
                },
                sentOrReceived: 'received'
            }));
        }
        catch(e:any)  {
            console.log("catch e: ", e)
            if (e.response?.status === 401) {
                AuthService.logout();
                history.push("/login");
                window.location.reload();
                

            }
            if(e.response?.status===404){
                console.log("notFound")
            }
            
            console.log("exception: ", e
            
            
            ); return []}
    };


    const changeOrg=(id:string)=>{
        setuserOrganisation(AuthService.getCurrentOrganisation())
        fetchReceivedInvoices(id,  currentPage, pageSize)
    }



    useEffect(()=>{

        subscribe("OrganizationChange", (data: any) => changeOrg(data.detail))
        fetchReceivedInvoices(userOrganisation, currentPage, pageSize);

        return () => {
            unsubscribe("OrganizationChange", (data: any) => changeOrg(data.detail));
        }

    },[ currentPage, pageSize])


    const columns: ColumnsType<invoice> = [
        {
            key: '0',
            title: "",
            dataIndex: 'integrationSpecificId',
            width: 40,
            render: (text, record)  =>
                <div style={{color:  record.status?.toLowerCase().includes("draft")?  "7918e6": "#000"}}>
                    <Tooltip title={record.sentOrReceived==="sent"? t("Sent_invoice") : t("Received_invoice")}>
                        {record.sentOrReceived==="sent"? <UploadOutlined style={{fontSize: "16px"}}  /> : <DownloadOutlined style={{fontSize: "16px"}} /> }
                    </Tooltip>
                </div>

        },
        {
            key: '1',
            title: t('Številka'),
            dataIndex: 'integrationSpecificId',
            width: 250,
            onFilter: (value, record) => {
                return (
                    String(record.customer? record.customer.name : "" ).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.documentReference? record.documentReference : "").toLowerCase().includes(value.toString().toLowerCase())
                )
            },
            render : (text, record)  =>
                <div>
                    <span style={{color: record.sentOrReceived==="sent"? "green": "#7918e6"}}>
                        <Link style={{color: record.sentOrReceived==="sent"? "green": "#7918e6"}}
                              to={record.sentOrReceived==="sent"? `/docs/${record.integrationSpecificId}` : `/docs/received/${record.integrationSpecificId}/${record.status}`}> 
                            <span>
                                {mapEnumToString(10)+" "+record.documentReference}
                            </span>
                        </Link><br/>
                        <span style={{fontSize: "13px", color: "#999" }}>{t("from")+" "+ServerdateToPoints(record.dateIssued)}</span>
                    </span>
                </div>
        },
        {
            title: t('Nasprotna_stranka'),
            dataIndex: 'integrationSpecificId',
            key: 'integrationSpecificId',
            //width: 250,
            render : (text, record)  =>
                <span>
                    {record.customer?  record.customer.name : "-"}<br/>
                    <span style={{fontSize: "13px", color: "#999" }}>
                        {record.customer? record.customer.taxNumber: ""}
                        {record.customer? " "+record.customer.address?.street+" " : ""}
                    </span>
                </span>
        },
        {
            title: t('Amount'),
            dataIndex: 'amount',
            key: 'amount',
            width: 150,
            align: "right",
            render : (text, record)  =>
                <span style={{color: InvoiceStatusColor(record.paymentStatus? record.paymentStatus : "")}}>
                    {
                        ToMoney(record.amount? record.amount : 0)
                    }

                    <br/>
                        <span style={{color: InvoiceStatusColor(record.paymentStatus? record.paymentStatus : "")}}>
                            {
                                record.dateTransaction?
                                    <span style={{fontSize: "12px" }}>    
                                        {ServerdateToPoints(record.dateTransaction)}&nbsp;
                                    </span>
                                    :""
                            }
                            <span>
                            {ToMoney(record.paidValue ? record.paidValue : 0)}
                            </span>
                        </span>
                
                
                </span>
        },
        {
            title: ' ',
            key: 'paymentStatus',
            fixed: 'right',
            width: 200,
            render: (text, record) =>
                <div>
                    <span style={{color: InvoiceStatusColor(record.paymentStatus? record.paymentStatus : "")}}>
                    {t(record.paymentStatus? record.paymentStatus?.replaceAll(" ","_") : "")}
                    </span>
                </div>
        },
    ];


    const handleTableChange: TableProps['onChange'] = (pagination) => {

        console.log("pagination:", pagination)
        setCurrentPage(pagination.current? pagination.current : 1)

    };


    return <>


        {

            (breakpoints.lg || breakpoints.xl)  ?
                <Table
                    locale={{
                        triggerDesc: 'Нажмите, чтобы отсортировать по убыванию',
                        triggerAsc: 'Нажмите, чтобы отсортировать по возрастанию',
                        cancelSort: 'Отменить сортировку'
                    }}
                    columns={columns}
                    dataSource={docs? docs: []}
                    loading={pageloading}
                    scroll={{ y: 'calc(53vh)' }}
                    pagination={{ pageSize: pageSize, current: currentPage, total: totalResults }} size="small"
                    onChange={handleTableChange}

                />

            :
                <List
                    dataSource={docs? docs: []}
                    renderItem={(item) => (
                        <List.Item
                            // actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">more</a>]}
                        >
                            <List.Item.Meta
                                title={<span>{item.customer?  item.customer.name : "-"}</span>}
                                description={
                                    <div>
                                    <span style={{color: item.status?.toLowerCase().includes("draft")? "green": "#000"}}>
                                        <Link style={{color: item.status?.toLowerCase().includes("draft") ? "green": "#000"}}
                                              to={item.sentOrReceived==="sent"? `/docs/${item.integrationSpecificId}` : `/docs/received/${item.integrationSpecificId}`}> 
                                            <span>
                                                {mapEnumToString(10)+" "+item.documentReference}
                                            </span>
                                        </Link><br/>
                                        <span style={{fontSize: "13px", color: "#999" }}>
                                            {t("from")+" "+ServerdateToPoints(item.dateIssued)}
                                        </span>
                                    </span>
                                    </div>
                                }
                            />
                            <div style={{textAlign:"right"}}>
                                {
                                    <span style={{color: InvoiceStatusColor(item.paymentStatus? item.paymentStatus : "")}}>
                                    {ToMoney(item.amount? item.amount : 0)}
                                        <br/>
                                    <span style={{color: InvoiceStatusColor(item.paymentStatus? item.paymentStatus : "")}}>
                                        {
                                            item.dateTransaction?
                                                <span style={{fontSize: "10px" }}>    
                                                    {ServerdateToPoints(item.dateTransaction)}&nbsp;
                                                </span>
                                                :""
                                        }
                                        <span>
                                        {ToMoney(item.paidValue ? item.paidValue : 0)}
                                        </span>
                                    </span>
                                </span>                                }
                            </div>
                        </List.Item>
                    )}
                />
        }
        
    
        
    </>
}

export default  ReceivedDocsTable;