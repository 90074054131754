// axiosInstance.ts
import axios from 'axios';
import { parseCookies, setCookie } from 'nookies';
import { refreshTokenRequest } from '../Services/auth-service';

const axiosInstance = axios.create();

// Add a request interceptor
axiosInstance.interceptors.request.use((config) => {
    // Get the token
    const cookies = parseCookies();
    const token = localStorage.getItem('usertoken'); //cookies.accessToken;
    const organization = localStorage.getItem('current_organization')
    const organizationId = localStorage.getItem('current_organizationId')
    // Do something before request is sent
    if (token) {
        config.headers.Authorization = `${token}`;
    }
    if (organization) {
        //fun fact: we can't use our naming convention here bacause headers are always lower case (organizationId -> organizationid)
        config.headers.organizationid = JSON.parse(organization)._id; //: make this either a cookie or a separate local storage item
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(undefined, async error => {
    let refreshToken:any
    if (error?.config && (['Unauthorized', 'no_user', 'no_token'].includes(error?.response?.data?.message)) && (refreshToken = parseCookies().refreshToken)) { //: add token exipry checker so we don't waste requests for nothing
        console.log('Request rejected with status code 403. Assuming token expired and refrshing.');

        const refresh = await axios.put(process.env.NEXT_PUBLIC_GATEWAY_URL + "/auth", {refreshToken})
            .catch(e => {
                console.log("Refresh failed. Checking if there was a successful refresh in the meanwhile.")
                const newCookies = parseCookies()
                if (newCookies.refreshToken !== refreshToken) return {data: {token: newCookies.accessToken, refreshToken: newCookies.refreshToken}, isStored: true}
                console.log("Refrsh failed. Redirectiong to logout");
                window.location.replace('/logout') //: check if this is allowed in nextjs/react standards
                return null
            })

        if (!refresh) throw error
        console.log('Refresh succeded. Retrying original request');

        // @ts-ignore
        if(!refresh['isStored']) {
            setCookie(null, 'accessToken', refresh.data.token, {
                maxAge: 14 * 24 * 60 * 60, // 14 days expiry
                path: "/"
            })
            setCookie(null, 'refreshToken', refresh.data.refreshToken, {
                maxAge: 14 * 24 * 60 * 60, // 14 days expiry
                path: "/"
            })
        }

        error.config.headers.Authorization = `Bearer ${refresh.data.token}`

        const tryAgain = await axios(error.config)
        return tryAgain
    }

    throw error //note that this behaviour is more predictable than Promise.reject(error) becuase Promise.reject doesn't get cought properly
});

export default axiosInstance;