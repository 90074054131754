import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {Button, Dropdown, Form, Input, Space} from "antd";
import bankAccount from "../../../types/BankAccount";
import {isIBAN, isBIC} from 'class-validator';
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import Organization from "../../../types/Organization";
import * as BankAccountService from "../../../Services/BsnkAccountService";


interface Params {
    bankAccount?: bankAccount | null;
    aftercreate? : (data: any) => void;
    afterDelete? : (data: any) => void;
}
export default function  BankAccountDetailPage({bankAccount, aftercreate, afterDelete}: Params) {
    const {t} = useTranslation();
    
    const history = useHistory();

    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }
    const userOrganisation = AuthService.getCurrentOrganisation();

    const [form] = Form.useForm();


    const [isLoading, setisLoading] = useState(false);

    
    
    useEffect(()=>{
        if(bankAccount){
            form.setFieldsValue({
                position_number: bankAccount.position_number,
                name: bankAccount.bankName,
                iban: bankAccount.iban,
                bic: bankAccount.bic
                
            });
        }
    })
    
    
    
    const [ibanValidStatus, setIbanValidStatus] = useState<"error"| undefined>()
    const [bicValidStatus, setBicValidStatus] = useState<"error"| undefined>()
    const [ibanValidHelp, setIbanValidHelp] = useState("")
    const [bicValidHelp, setBicValidSHelp] = useState("")
    
    const handleFinish=(values: any)=>{
        
        console.log("bankAccount: ", bankAccount)
        console.log("values: ", values)
        
        console.log("handleFinish values: ", values)
        var errors=0;
        if(!isIBAN(values.iban)){
            setIbanValidStatus("error")
            setIbanValidHelp(t("IbanError"))
            errors++;
        }
        if(!isBIC(values.bic)){
            setBicValidStatus("error")
            setBicValidSHelp(t("BicError"))
            errors++;
        }
        
        if(errors===0){
            if(aftercreate){
                aftercreate(values);
            }

            if(userOrganisation.bankAccounts){
                userOrganisation.bankAccounts.push({
                    iban: values.iban,
                    bic: values.bic,
                    bankName: values.bankName ? values.bankName : ""
                });
            }

            
            BankAccountService.PUTBankAccount(userOrganisation.bankAccounts?  userOrganisation.bankAccounts : [{
                iban: values.iban,
                bic: values.bic,
                bankName: values.bankName ? values.bankName : ""
            }]);

        }
        
    }


    const handleDelete=()=>{
        if(afterDelete){
            console.log("position_to_delete: ", bankAccount?.position_number)
            afterDelete(bankAccount?.position_number)
        }
    }
   
    
    
    const dontsave=()=>{
        if(aftercreate){
            aftercreate(
                {
                    position_number: "cancel",
                    bankName: null,
                    iban: null,
                    bic: null

                });
        }
    }
    
    return <>
    
    
    <Form
        form={form}
        autoComplete="off"
        layout="horizontal"
        labelCol={{ flex: '120px' }}
        labelAlign="right"
        labelWrap
        onFinish={handleFinish}
        wrapperCol={{ flex: "190px" }}
    >

        <Form.Item
            name="position_number"
            label="position_number"
            hidden={true}
        >
            <Input  />
            
        </Form.Item>

        

        <Form.Item
            name="bankName"
            label="Bank name"
        >
            <Input placeholder="Add bank title"  />
        </Form.Item>
        <Form.Item
            name="iban"
            label="IBAN"
            validateStatus={ibanValidStatus}
            help={ibanValidHelp}
        >
            <Input placeholder="SI56123412341234123" 
                   onChange={()=> {
                       setIbanValidStatus(undefined);
                       setIbanValidHelp("")
                   }
            } />
        </Form.Item>
        <Form.Item
            name="bic"
            label="SWIFT"
            validateStatus={bicValidStatus}
            help={bicValidHelp}
        >
            <Input placeholder="LJAASI1A" 
                   onChange={()=> {
                       setBicValidStatus(undefined)
                       setBicValidSHelp("")
                   }
            }  />
        </Form.Item>
        
       
        <Space>
            <Button 
                type={"primary"}
                htmlType="submit" style={{marginLeft:"20px"}}>
                {t("Shraniti")}
            </Button>
            <Button className={"orangeButton"} onClick={dontsave} style={{marginLeft:"10px"}}>
                {t("Ne_shranite_sprememb")}
            </Button>

            <Dropdown menu={{'items': [{ key: '1',  label: <Button type="primary" danger>{t("Delete")}</Button> }], onClick: handleDelete }} >
                <Button type="text">{t("More")}..</Button>
            </Dropdown>
        </Space>
            
       
    </Form>
    
    </>

}


