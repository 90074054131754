import React, { useState } from 'react';
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CommonTable from './Components/CommonTable';
import CommonListFilter from '../../types/CommonListFilter';
import {Button, Col, Divider, Row} from "antd";


export const WarehousePage = () => {
    const history= useHistory();

    const {t} = useTranslation();

    const handleRowClick = (record: any) => {
        history.push(`/warehouse/${record.key}`);
    };

    const [warehouseFilter, setWarehouseFilter]=useState<CommonListFilter>({
      searchText: "",
      dateFrom: "",
      dateTo: "",
      selectedDates: "",
  })

  
  
  const setCBFilterFunc=(filter: any)=>{
    setWarehouseFilter(filter);
      //console.log("page comp:", filter)
  }
  
    return (
      <div>
          <Row>
              <Col span={14}>
                <h1>{t("Warehouse")}</h1>
              </Col>
              <Col span={5} style={{display: "flex", alignItems: "center"}}>
                  <Button
                      onClick={()=>{history.push("/editmovement/")}}
                      style={{float: "right"}}
                      type={"primary"} >+ {t("New_movement")}</Button>
              </Col>
          </Row>
          <Divider />
        <CommonTable filter={warehouseFilter} onRow={(record) => ({ onClick: () => handleRowClick(record) })} />
      </div>
    );
  };

export default WarehousePage;