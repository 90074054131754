import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import * as AuthService from "../Services/auth-service";
import {Divider, Drawer, Select, Space, Spin} from "antd";
import React, {useEffect, useState} from "react";
import bankAccount from "../types/BankAccount";
import BankAccountDetailPage from "../Pages/Settings/Components/BankAccountDetails";
import {PlusOutlined} from "@ant-design/icons";


type CounterPartProps = {
    defaultSelected? : string;
    afterCreate?: (data: any) => void;
    required? : boolean;
    isDisabled? : boolean
    extraAcc? : any
};

const BankAccSelect = ({defaultSelected, afterCreate, required, isDisabled, extraAcc }: CounterPartProps) => {
    const {t} = useTranslation();


    const history = useHistory();
    const user = AuthService.getCurrentUser();

    const userOrganisation = AuthService.getCurrentOrganisation();
    
    const [bankAccountsList, setBankAccountsList] = useState<bankAccount[] | null>(null)
    const [selectedBankAccount, setSelectedBankAccount] = useState<string|undefined>(undefined)


    const[openBankAccount,setopenBankAccount]= useState(false);
    
    const [componentLoading, setComponentLoading] = useState(false)



    


    useEffect(()=>{
        if(bankAccountsList===null){
            setComponentLoading(true)
            setBankAccountsList(userOrganisation.bankAccounts ? userOrganisation.bankAccounts : [])
            if(required && userOrganisation.bankAccounts && userOrganisation.bankAccounts.length===0){
                setopenBankAccount(true)
            }
            if(extraAcc && userOrganisation.bankAccounts){
                console.log("extraAcc: ", extraAcc);
                console.log("userOrganisation.bankAccounts: ", userOrganisation)
                
                const foundOne = userOrganisation.bankAccounts.find((a:any)=>a.iban===extraAcc.iban);
                setSelectedBankAccount(foundOne?.iban)
            }
            setComponentLoading(false)
        }
        
    }, [defaultSelected, extraAcc])

    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
        setSelectedBankAccount(value);
        var acc=bankAccountsList?.find(f=>f.iban?.toString()===value);
        if(afterCreate) {
            afterCreate(acc);
        }
    };

    const setNewAccount=(acc: bankAccount)=>{

        setopenBankAccount(false)

        if(acc.iban){
            userOrganisation.bankAccounts.push(acc)
            localStorage.setItem("current_organization", JSON.stringify(userOrganisation));

            if(bankAccountsList)
            {
                bankAccountsList.push(acc);
            } 
            else{
                setBankAccountsList([acc])
            }
            console.log("acc after create: ", acc)
            setSelectedBankAccount(acc.iban)
            if(afterCreate) {
                afterCreate(acc);
            }
            
            console.log("userOrganisation: ", userOrganisation)
            
            //добавить put организации с новым банковским счетом
            
            
            
        }
    }






    return <Spin spinning={componentLoading}>

        <Select
            value={selectedBankAccount}
            options={bankAccountsList? bankAccountsList.map((tr) => ({
                label : tr.bankName ? <span>{tr.bankName} &nbsp; <span style={{color: "#999"}}>{tr.iban}</span></span> : "",
                value : tr? tr.iban : undefined,
            }) ): [{value: "", label: ""}]}
            onChange={(iban)=>handleChange(iban)}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }}/>
                    <Space style={{ padding: '0 8px 4px' }}>
                        <a onClick={()=>{setopenBankAccount(true)}}> <PlusOutlined/> {t("Add_new_bank_account")}</a>
                    </Space>
                </>)}

            disabled={isDisabled}


        />



        <Drawer title={<>{t("Edit_bank_account")}<br/>
            <span style={{fontSize:"14px", fontWeight: "normal"}}>{t("No_Bank_froinvoice")}</span></>}
                placement="right"
                onClose={()=>setopenBankAccount(false)}
                open={openBankAccount}>
            <BankAccountDetailPage
                bankAccount={undefined}
                aftercreate={setNewAccount}/>
        </Drawer>

    </Spin>

}

export default BankAccSelect;

