import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../Services/auth-service";
import {Row, Space} from "antd";
import OrganizationSelect from "./OrganizationSelect";
import BreadCrumbsComp from "./BreadCrumbsComp";

const AppHeader = () => {
    const history= useHistory();
    const user = AuthService.getCurrentUser();


    if(!user){
        history.push("/login");
    }

    console.log("user:", user)
    return <div style={{margin: "0px 20px"}}>
        
        <Space style={{float: "left",  height: '64px'}}>
            <BreadCrumbsComp />
        </Space>

<Space style={{float: "right",  height: '64px'}}>        
    <div style={{float: "right"}}>
        
        <OrganizationSelect />
        <span> | </span>
        
        { user.user?.firstName +" "+ user.user?.lastName}
    </div>
</Space>
    </div>

}

export default AppHeader