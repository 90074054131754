import React, {useContext, useEffect, useState} from 'react';

import i18n from "i18next";
import {useTranslation} from "react-i18next";
import {Button, Dropdown, MenuProps, Space} from "antd";
import {DownOutlined} from "@ant-design/icons";
import * as AuthService from "../Services/auth-service";
import authHeader from "../Services/auth-header";
import {DATA_URL} from "../Services/auth-service";
import axios from "axios";
import Organization from "../types/Organization";
import {NotificationContext, publish} from "./Notifications/NotificationContext";
import {useHistory} from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";

const OrganizationSelect = () => {
    const {t} = useTranslation();
    const [currentOrg, setcurrentOrg] = useState("");
    const {currentOrganizationId, setcurrentOrganizationId} =  useContext(NotificationContext);


    const [render, setrender] = useState(true)

    const [organisations, setOrganisations] = useState<Organization[]>(AuthService.getUsersOrganisations())

    const history= useHistory();


    if(!AuthService.getCurrentUser()){
       // history.push("/login")
    }
    
    

    
    const the_orgs=AuthService.getUsersOrganisations()
    console.log("AuthService the_orgs: ", the_orgs )
    
    const [items, setItems] = useState<MenuProps['items']>(the_orgs ? the_orgs.map((org:any)=>
        ({key: org.id ? org.id : "0", label: org.legalName ? org.legalName : "-", id: org.id, legalName: org.legalName })) : null)

    let orgs= AuthService.getUsersOrganisations();

    //setItems( )



    useEffect(()=>{
        if(render){
            if(organisations===null){

                if(orgs===null){


                    axiosInstance.get(DATA_URL + "organization/", { headers: authHeader()
                    })
                        .then((org_response) => {
                            console.log("org_response.data: ", org_response.data)
                            localStorage.setItem("organizations", JSON.stringify(org_response.data));
                            orgs= AuthService.getUsersOrganisations();
                            setOrganisations(org_response.data);
                            orgs = org_response.data;
                            //console.log("organizations: ", orgs);
                            let curr_org = AuthService.getCurrentOrganisation()
                            if(curr_org){
                                setcurrentOrg(curr_org.label);
                            }
                            else{
                                localStorage.setItem("organizations", JSON.stringify(org_response.data[0]));
                                setcurrentOrg(org_response.data[0].legalName)
                            }
                        })
                        .catch((error)=>{
                            console.log("catch error: ", error)
                            if(error.response.status===401){
                                //AuthService.logout();
                                //history.push("/login");

                            }
                        })
                }
                else{
                    setOrganisations(orgs);
                    setItems(orgs? orgs.map((org:any)=>
                        ({key: org.id ? org.id : "0", label: org.legalName ? org.legalName : "-"})) : [{key: "0", label: "0"}] )
                    
                    
                    
                    
                    let curr_org = AuthService.getCurrentOrganisation()
                    if(curr_org){
                        setcurrentOrg(curr_org.legalName);
                    }
                    else{
                        curr_org=orgs[0];
                        localStorage.setItem("current_organization", JSON.stringify(curr_org));
                        localStorage.setItem("current_organizationId", curr_org._id);
                        setcurrentOrg(curr_org.legalName);
                    }
                }
            }
            else {
                let curr_org = AuthService.getCurrentOrganisation()
                if (curr_org) {
                    setcurrentOrg(curr_org.legalName);
                    setcurrentOrganizationId(curr_org.id)

                } else {
                    curr_org=AuthService.getUsersOrganisations()[0];
                    console.log("curr_org: ", curr_org)
                    if(curr_org){
                        localStorage.setItem("current_organization", JSON.stringify(curr_org));
                        setcurrentOrg(curr_org.legalName);
                        setcurrentOrganizationId(curr_org.id)
                    }
                    else{
                       // AuthService.logout();
                        //history.push("/login");
                    }
                }
            }
            
 
        }
        setrender(false)




        
        
    }, [])
    
    
    /*
    useEffect(()=>{
        console.log("organisations: ", organisations)
        setItems(organisations? organisations.map((org)=>
            ({key: org.id ? org.id : "0", label: org.legalName ? org.legalName : "-"})) : [{key: "0", label: "0"}] )
    }, [organisations])
    
     */
    
    /*
    const items: MenuProps['items'] = organisations? organisations.map((org)=>
        ({key: org.id ? org.id : "0", label: org.legalName ? org.legalName : "-"})) : [{key: "0", label: "0"}] 
        
     */

    const handleOrganisationClick: MenuProps['onClick'] = (e) => {

        // @ts-ignore   current_organization
        var curr = organisations.find(i=>i._id===e.key)
        console.log("e: ", e);
        console.log("organisations: ", organisations);
        console.log("items:", items)
        console.log("curr: ", curr);
        localStorage.setItem("current_organization", JSON.stringify(curr));
        // @ts-ignore
        setcurrentOrg(curr.legalName)
        setcurrentOrganizationId(curr? curr.id? curr.id : "" : "")



        publish("OrganizationChange", curr? curr.id? curr.id : "" : "")
        

    };

    const menuProps = {
        items,
        onClick: handleOrganisationClick,
    };
    
    
    

 
    return(
        <div 
            style={{paddingTop: "8px"}} 
        >
           
        <Dropdown
            //autoAdjustOverflow ={false}
            menu={menuProps}>
            <Button>
                <div style={{maxWidth: "200px", overflow: "hidden"}}>
                    {currentOrg}
                    <DownOutlined />
                </div>
            </Button>
        </Dropdown>

            
        </div>
    )

   



}

export default OrganizationSelect;