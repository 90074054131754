import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {Avatar, Button, Card, Checkbox, Col, Divider, Form, Grid, Input, message, Row, Spin} from "antd";
import {CrownOutlined} from "@ant-design/icons";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import {useTranslation} from "react-i18next";
import Organization from "../../../types/Organization";
import {publish, subscribe, unsubscribe} from "../../../CommonComponents/Notifications/NotificationContext";
import {DATA_URL, loadUsersOrganisations} from "../../../Services/auth-service";
import BankAccountTable from "./BankAccountsTable";
import bankAccount from "../../../types/BankAccount";
import axiosInstance from "../../../utils/axiosInstance";




const CompanySettingsPage = () => {

    const {t} = useTranslation();


    const history= useHistory();
    const user = AuthService.getCurrentUser();
   
    if(!user) {
        history.push("/login");
    }


    const { useBreakpoint } = Grid;
    const breakpoints = useBreakpoint();

    const [form] = Form.useForm();
    const onFinishFailed = () => {
        message.error('Submit failed!');
    };


    const [pageLoading, setPageLoading] = useState(false)
    const [ownCompany, setownCompany] = useState<Organization | null >(null);

    const [refresh, setRefresh]=useState(false)
    

    useEffect(()=>{
        subscribe("OrganizationChange", (data: any) => changeOrg(data.detail))

        if(ownCompany===null){
            setPageLoading(true)
            const ow=AuthService.getCurrentOrganisation()
            console.log("ow:", ow)
            setownCompany(ow)
            form.setFieldsValue({
                title: ow?.legalName,
                davcnaStevilka: ow?.taxNumber ,
                maticnaStevilka: ow?.taxNumber ,
                adress1: ow?.address? ow.address.street : "",
                adress2: ow?.address? ow.address.city  : "",
                postcode: ow?.address ? ow.address.zipCode : "",

            });
            setPageLoading(false)
        }

        return () => {
            unsubscribe("OrganizationChange", (data: any) => changeOrg(data.detail));
        }

    })
    
    




    const onFinish = (values: any) => {

        setPageLoading(true)
        console.log("values: ", values)

        localStorage.removeItem("organizations");
        localStorage.removeItem("current_organization");




        const toSend: Organization= {
            taxNumber: values.davcnaStevilka,
            isVatRegistered: true,
            registrationNumber: values.maticnaStevilka,
            address: {
                street: values.adress1,
                city: values.adress2,
                zipCode: values.postcode,
                countryCode: "SI"
            },
            bankAccounts: ownCompany?.bankAccounts ? ownCompany?.bankAccounts.map((acc) => ({
                    iban: acc.iban,
                    bic: acc.bic,
                    bankName: acc.bankName ? acc.bankName : ""
                }
            )): []
        };
        
        
        console.log("toSend: ", toSend)

        axiosInstance.put(AuthService.DATA_URL+'organization/'+ownCompany?.id, 
            toSend,
            {headers: authHeader()})
            .then((putres) =>{
                console.log("put result: ", putres.data);

                const ow=putres.data;
                console.log("ow:", ow)
                form.setFieldsValue({
                    title: ow?.legalName,
                    davcnaStevilka: ow?.taxNumber,
                    maticnaStevilka: ow?.taxNumber,
                    adress1: ow?.address ? ow.address.street : "",
                    adress2: ow?.address ? ow.address.city : "",
                    postcode: ow?.address ? ow.address.zipCode : "",
                });
                
                
                message.success('Podatki so bili uspešno shranjeni');
                AuthService.loadUsersOrganisations();
                
                console.log("localStorage.setItem(\"organizations\": ", localStorage.getItem("organizations"))
                
                localStorage.setItem("current_organization", JSON.stringify(ow));
                console.log("localStorage.getItem(\"current_organization\": ", localStorage.getItem("current_organization"))
                setownCompany(ow)


                publish("OrganizationChange", ow.id)

                setPageLoading(false)

                }
            )
            .finally(()=>{

               // var tt=!forceReload;
               // setforceReload(tt);

            });


        const element = document.getElementById("obrazec");
    }
    const handleIsLoad =(isLoaded : boolean) =>{
        if(isLoaded) {
        }
    }


    const changeOrg=(id: any)=>{


        const org=AuthService.getCurrentOrganisation()
       
        
        console.log("org: ", org)
        form.setFieldsValue({
            title: org.legalName,
            davcnaStevilka: org.taxNumber ,
            maticnaStevilka: org.taxNumber ,
            adress1: org.address? org.address.street : "",
            adress2: org.address? org.address.city : "",
            postcode : org.address? org.address.zipCode : "",
            //iban: response.data ? response.data[0].iban : "",
            //swift: response.data ? response.data[0].swift : "",

        });
        setownCompany(org)
    }

    
   
   



    const handleAccounts=(accs: bankAccount[])=>{
        
        //console.log("accs: ", accs)
        
        setownCompany(prev => ({
            ...prev,
            hasCustomerPortal: true,
            bankAccounts :accs
        }));
        
         
        
         
    }




    return(
        <Spin spinning={pageLoading}> 
        <div>
            <Row>
                <Col 
                    span={24}
                >
                 
                    <Row>
                        <Col span={24}>
                            <Card style={{marginTop: "20px"}}>
                                <Form
                                    form={form}
                                    name="CompanySettings"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    layout={(breakpoints.lg || breakpoints.xl) ? "horizontal" : "vertical"}
                                    labelCol={(breakpoints.lg || breakpoints.xl) ? { flex: '120px' } : {}}
                                    labelAlign="right"
                                    labelWrap
                                    wrapperCol={(breakpoints.lg || breakpoints.xl) ? { flex: "190px" } : {}}
                                    colon={false}
                                    //style={{ maxWidth: 600 }}
                                >

                                    <Row>
                                        <Col
                                            xs={24} sm={24} md={24} lg={8} xl={8}
                                            //span={8} 
                                            style={{borderRight: (breakpoints.lg || breakpoints.xl) ? "1px solid #f0f0f0" : "none"}}>
                                            <Form.Item
                                                name="title"
                                                label={t("Naziv")}
                                                rules={[{ required: true }, { type: 'string', warningOnly: true }]}
                                            >
                                                <Input placeholder="Naziv Podetja d.o.o." />
                                            </Form.Item>
                                            <Form.Item
                                                name="davcnaStevilka"
                                                label={t("Davčna_Številka")}
                                                rules={[{ type: 'string', warningOnly: true }]}
                                            >
                                                <Input placeholder="SI12345678" />
                                            </Form.Item>
                                            {/* 

                                            <Form.Item
                                                name="maticnaStevilka"
                                                label={t("Matična_Številka")}
                                                rules={[{ type: 'string', warningOnly: true }]}
                                            >
                                                <Input placeholder="0123456789" />
                                            </Form.Item>
                                            */}
                                            <Form.Item
                                                name="adress1"
                                                label={t("Naslov") + " (1)"}
                                                rules={[{ type: 'string', warningOnly: true }]}
                                            >
                                                <Input placeholder="Ulica, 11" />
                                            </Form.Item>
                                            <Form.Item
                                                name="adress2"
                                                label={t("Naslov") + " (2)"}
                                                rules={[{ type: 'string', warningOnly: true }]}
                                            >
                                                <Input placeholder="Mesto" />
                                            </Form.Item>
                                            <Form.Item
                                                name="postcode"
                                                label={t("Postcode")}
                                                rules={[{ type: 'string', warningOnly: true }]}
                                            >
                                                <Input placeholder="Post code" />
                                            </Form.Item>

                                        </Col>
                                        <Col span={15} offset={1}>

                                            <Form.Item
                                               
                                                name="banks"
                                                label={t("Bank_accounts") }
                                                rules={[{ type: 'string', warningOnly: true }]}
                                            >
                                            </Form.Item>
                                            <BankAccountTable 
                                                accounts={ownCompany?.bankAccounts?
                                                    ownCompany?.bankAccounts.map((acc, idx)=>({
                                                    position_number: idx.toString(),
                                                    iban: acc.iban,
                                                    bic: acc.bic
                                                    
                                                })): null } aftercreate={handleAccounts}/>



                                            {/*
                                            
                                            <Form.Item
                                                name="iban"
                                                label="IBAN"
                                            >
                                                <Input placeholder="SI56123412341234123"  />
                                            </Form.Item>
                                            <Form.Item
                                                name="swift"
                                                label="SWIFT"
                                            >
                                                <Input placeholder="LJAASI1A"  />
                                            </Form.Item>
                                            */}

                                            {/* 
                                            <Row>
                                                <Col span={12}>
                                                    <p style={{textAlign: "center"}}>{t("Žig")}:</p>
                                                    <div style={{
                                                        //background: "url('http://localhost:3000/img/zig.png') no-repeat",
                                                        //backgroundImage: "url('" + zigPic + "')",
                                                        backgroundRepeat: "no-repeat",
                                                        padding: "20px 10px",
                                                        //width: "200px",
                                                        backgroundSize : "contain",
                                                        backgroundPosition: "center",
                                                        width: "100%", height: "80px"
                                                    }}>

                                                    </div>
                                                </Col>
                                                <Col span={12}>
                                                    <p style={{textAlign: "center"}}>{t("Podpis")}:</p>
                                                    <div style={{
                                                        //background: "url('http://localhost:3000/img/signature.png') no-repeat",
                                                       // backgroundImage: "url('" + podpisPic + "')",
                                                        backgroundRepeat:"no-repeat",
                                                        backgroundSize : "contain",
                                                        backgroundPosition: "center",
                                                        width: "100%", height: "80px"
                                                    }}>

                                                    </div>
                                                </Col>

                                            </Row>
*/}
                                        </Col>
                                        {/* 
                                        <Col span={8}>
                                            <Form.Item wrapperCol={{flex: "100%"}} name="isTransporter">
                                                <Checkbox checked={isTransporter}
                                                    onChange={(e)=>setisTransporter(e.target.checked)}
                                                >{t("Predstavljam_prevozno_prevozniško_podjetje")}</Checkbox>
                                            </Form.Item>
                                            <Form.Item wrapperCol={{flex: "100%"}} name="AcceptOpenOrders">
                                                <Checkbox  checked={AcceptOpenOrders}
                                                    onChange={(e)=>setAcceptOpenOrders(e.target.checked)}
                                                >{t("Želim_prejemati_javne_ponudbe_za_prevoz")}</Checkbox>
                                            </Form.Item>
                                            <Form.Item wrapperCol={{flex: "100%"}} name="isOperator">
                                                <Checkbox checked={isOperator}
                                                    onChange={(e)=>setisOperator(e.target.checked)}
                                                >{t("Predstavljam_pošiljatelja_špediterja")}</Checkbox>
                                            </Form.Item>
                                            <Form.Item wrapperCol={{flex: "100%"}} name="AcceptOpenReplies">
                                                <Checkbox checked={AcceptOpenReplies}
                                                    onChange={(e)=>setAcceptOpenReplies(e.target.checked)}
                                                >{t("Želim_imeti_dostop_do_razpoložljivih_prevoznikov")}</Checkbox>
                                            </Form.Item>

                                        </Col>
                                    */}
                                    </Row>
                                    <Divider/>
                                    <Button className={"orangeButton"} htmlType="submit" style={{marginLeft:(breakpoints.lg || breakpoints.xl) ? "120px" : ""}}>
                                        {t("Shraniti")}
                                    </Button>

                                    


                                </Form>
                            </Card>
                        </Col>

                    </Row>

                </Col>

                {/*

            <Col span={8} style={{paddingLeft: "40px"}} id={"obrazec"}>
                <div className="shadow"
                     style={{backgroundColor: "#fff", padding: "20px",
                         boxShadow: "0 4px 16px 0 rgba(0, 0, 0, 0.08)",
                         border: "1px solid #e6e6e6",
                         height: "500px"
                     }}
                >
                <ShowDocPage forceReload={forceReload} isLoaded={handleIsLoad} />
                </div>
               
            </Col>
                    */}

            </Row>












        </div>
        </Spin>
    )};
export default CompanySettingsPage;