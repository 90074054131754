import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import {Button, Col, ConfigProvider, Grid, Modal, Row, Tabs, TabsProps} from "antd";
import CommonListFilter from "../../types/CommonListFilter";
import ProductListFilter from "../Products/Components/ProductListFilter";
import DocListFilter from "./Components/DocListFilter";
import UserSettingsPage from "../Settings/Components/UserSetting";
import CompanySettingsPage from "../Settings/Components/CompanySetting";
import {useTranslation} from "react-i18next";
import SentDocsTable from "./Components/SentDocTable";
import ReceivedDocsTable from "./Components/ReceivedDocTable";
import UploadDoc from "../../CommonComponents/UploadDoc";




const DocList = () => {
    const history= useHistory();
    const user = AuthService.getCurrentUser();

    console.log("user: ", user)
    
    const {t} = useTranslation();


    const { useBreakpoint } = Grid;
    const breakpoints = useBreakpoint();





    if(!user){
    //    history.push("/login");
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramValue = urlParams.get('tab');


    const [sentFilter, setSentFilter]=useState<CommonListFilter>({
        searchText: "",
        dateFrom: "",
        dateTo: "",
        selectedDates: "",
    })


    const [receivedFilter, setReceivedFilter]=useState<CommonListFilter>({
        searchText: "",
        dateFrom: "",
        dateTo: "",
        selectedDates: "",
    })
    
    const setSendFilterFunc=(filter: any)=>{
        setSentFilter(filter);
        //console.log("page comp:", filter)
    }

    const setReceivedFilterFunc=(filter: any)=>{
        setReceivedFilter(filter);
        //console.log("page comp:", filter)
    }

    const onChange = (key: string) => {
        console.log(key);
        localStorage.setItem("docsTab", key.toString())
        setCurrentTab(key);
    };

    const [currenTab, setCurrentTab] = useState<string|null>(null);
    useEffect(()=>{
        console.log("localStorage", localStorage.getItem("docsTab"))
        console.log("sentFilter: ", sentFilter)
        console.log("receivedFilter: ", receivedFilter)
        if(localStorage.getItem("settingsTab")!=null){
            setCurrentTab(localStorage.getItem("docsTab")?localStorage.getItem("docsTab"): "1")
        }
    
    }, [paramValue, sentFilter, receivedFilter])


    const items: TabsProps['items'] = [
        {
            key: '1',
            label: t('Sent_invoices'),
            children:  <>
                <SentDocsTable/>
            </>
        },
        {
            key: '2',
            label: t('Received_invoices'),
            children: <>
                   <ReceivedDocsTable /> 
            </> 
        },

    ]



    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalCancel = () => {
        setIsModalOpen(false);
    };

    const handleModalOk=()=>{
        setIsModalOpen(false);
    }




    return <>

        <Row gutter={[8, 8]}>
            
            <Col /*span={14}*/  xs={24} sm={16} md={14} lg={14} xl={14}>
                <h1>
                    {t("Dokumenti")} 
                </h1>
            </Col>
            <Col
                //span={5}
                xs={24} sm={24} md={5} lg={5} xl={5}
                 style={{display: "flex", alignItems: "center"}}>
                <Button type={"primary"}
                        style={{float: "right", minWidth: "200px"}}
                        onClick={()=>setIsModalOpen(true)}
                >
                    + {t("Nov_prejeti_račun")}</Button>
            </Col>
            <Col 
                //span={5}
                xs={24} sm={24} md={5} lg={5} xl={5}
                style={{display: "flex", alignItems: "center"}}>



            <ConfigProvider
                    theme={{
                        components: {
                            Button: {
                                colorPrimary: `green`,
                                colorPrimaryHover: `#34a234`,
                                lineWidth: 0,
                            },
                        },
                    }}
                >
                
                <Button type={"primary"} 
                        style={{float: "right",  minWidth: "200px"}}
                        onClick={()=>{history.push("docs/createdoc")}}>
                    + {t("Nov_izdani_račun")}</Button>
                </ConfigProvider>
                
                
                
            </Col>

        </Row>

        <Tabs activeKey={paramValue===null ? currenTab===null? "1": currenTab : paramValue } items={items} onChange={onChange} />






        <Modal open={isModalOpen}
               onOk={handleModalOk}
               title={t("Prenesite_dokument")}
               centered
               width={580}
               onCancel={handleModalCancel}
            //footer={[]}
        >

            <UploadDoc objectId={null} objectMode={10} vrstaDoka={0}/>
        </Modal>
        
        
       

    </>

}

export default DocList