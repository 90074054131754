import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {useTranslation} from "react-i18next";
import {Button, Divider, Drawer, Modal, Select, Space, Spin} from "antd";
import ProductDetailPage from "../../Products/Components/ProductDetails";
import Product from "../../../types/Product";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import {ExclamationCircleFilled, PlusOutlined, PrinterOutlined} from '@ant-design/icons';
import {DATA_URL} from "../../../Services/auth-service";
import InvoiceItem from "../../../types/invoiceItem";
import OrganisationDTO from "../../../types/OrganisationDTO";
import EditContractorComp from "../../Counterparts/Components/EditComponent";
import CreateContractor from "../../Counterparts/Components/CreateComponent";
import axiosInstance from "../../../utils/axiosInstance";



type CounterPartProps = {
    defaultSelected? : string;
    afterCreate?: (data: any) => void;
    isDisabled? : boolean
};

const CounterPartSelect = ({defaultSelected, afterCreate, isDisabled }: CounterPartProps) => {
    const {t} = useTranslation();


    const history = useHistory();
   // const user = AuthService.getCurrentUser();

    const userOrganisation = AuthService.getCurrentOrganisation();






    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
        setSelectedCounterPart(value);
        var prod=counterpartList?.find(f=>f.integrationSpecificId?.toString()===value);
        console.log("ContractorSelect - contractor for afterCreate:", prod)
        if(afterCreate) {
            console.log("ProductSelect - prod for afterCreate:", prod)
            afterCreate(prod);
        }
    };

    const [openDrawer, setopenDrawer] = useState(false)

    const[counterpartList, setCounterpartList]=useState<OrganisationDTO[]|null>(null)
    const[selectedCounterPart, setSelectedCounterPart] = useState<string|null|undefined>(undefined);
    const[pageloading, setpageloading] = useState<boolean>(false)



    useEffect(()=>{
        if(counterpartList===null){
            console.log("defaultSelected: ", defaultSelected)
            setpageloading(true)
            axiosInstance.get(AuthService.DATA_URL + "integration/customer/"+userOrganisation.id+"/?page=1", 
                {headers: authHeader()}
        )
                .then((response)=>{
                    console.log(response.data)
                    console.log("defaultSelected: ", defaultSelected)
                    setCounterpartList(response.data.data);
                    if(defaultSelected){
                        setSelectedCounterPart(defaultSelected.toString());
                    }
                    setpageloading(false)
                })
                .catch(function (error) {
                    console.log("error: ", error)
                    setCounterpartList([]);
                    setpageloading(false)

                    if(error.response.status===401){
                        //AuthService.logout();
                        //history.push("/login");
                      //  window.location.reload();

                    }
                    if(error.response.status===404){
                        console.log("notFound")
                    }

                })

        }
    })


    const onSearch = (value: string) => {
        //console.log('search:', value);
    };

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const createNew=()=>{
        setopenDrawer(true)
    }

    const setnewId=(newCounterPart: OrganisationDTO)=>{
        counterpartList?.push(newCounterPart);
        setSelectedCounterPart(newCounterPart.integrationSpecificId?.toString())
        console.log("CounterPartSelect - newCounterPart for afterCreate:", newCounterPart)
        
        
        if(afterCreate){

            afterCreate(newCounterPart);

        }
        else {
            console.log("newCounterPart: ", newCounterPart)
        }
        setopenDrawer(false)
    }



    return <Spin spinning={pageloading} >
        <Select
            showSearch
            //style={{ width: 120 }}
            optionFilterProp="children"
            onChange={(e: any)=> {console.log(e); handleChange(e)}}
            onSearch={onSearch}
            filterOption={filterOption}
            value={selectedCounterPart}
            notFoundContent={<span><PlusOutlined/> <a onClick={()=>{createNew()}}>Add new counterpart</a> </span>}

            options={counterpartList? counterpartList.map((tr, index) => ({
                label : tr.name ? tr.name : "",
                value : tr.integrationSpecificId?.toString()? tr.integrationSpecificId?.toString() : "",
                disabled: tr.address?.countryCode? tr.address?.countryCode !== "SI" : true
            }) ): [{value: "", label: "", disabled: false}]}

            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }}/>
                    <Space style={{ padding: '0 8px 4px' }}>
                        <a onClick={()=>{createNew()}}> <PlusOutlined/> {t("Nov_partner")}</a>
                    </Space>
                </>)}
            disabled={isDisabled}
            
            
        />

        <Drawer title={t("Ustvarite_novo_podjetje")} placement="right"
                onClose={()=>setopenDrawer(false)}
                open={openDrawer}>
            <CreateContractor key={0} afterCreate={setnewId}  />
        </Drawer>
    </Spin>

};
export default CounterPartSelect;