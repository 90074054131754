export default function authHeader() {
    const userStr = localStorage.getItem("user");
    const orgStr = localStorage.getItem("current_organization");
    let user = null;
    let org = null;
    if (userStr)
        user = JSON.parse(userStr);
    if (orgStr)
        org = JSON.parse(orgStr);
    console.log("org::::::", org)
    
     

    
    if (user && user.token) {
        if(org)
        return { Authorization: user.token, organizationid: org._id };
        else{return { Authorization: user.token}; }// for Spring Boot back-end , organizationid: org
        // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
    } else {
        return { Authorization: '' }; // for Spring Boot back-end
        // return { 'x-access-token': null }; // for Node Express back-end
    }
}