import React, {useEffect, useState} from 'react';
import { Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
//import data from '../../../data.json';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CommonListFilter from '../../../types/CommonListFilter';
import {waregouseService} from "../../../Services/warehouseService";
import * as AuthService from "../../../Services/auth-service";
import InvoiceItem from "../../../types/invoiceItem";
import Product from "../../../types/Product";

/*
interface Product {
  key: string;
  name: string;
  unit: string;
  stock: number;
  purchasePrice: number;
}


 */
interface ProductStock {
  count? : number;
  item? : Product;
  integrationSpecificId? : string;
  name? : number;
}

interface CommonTableProps {
    onRow: TableProps<Product>['onRow'];
    filter: CommonListFilter;
  }

const CommonTable = ({ filter } : CommonTableProps) => {
  const history= useHistory();

  const {t} = useTranslation();

  
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ProductStock[]>([]);

  const columns: ColumnsType<ProductStock> = [
    {
      title: t('Product_name'),
      dataIndex: 'name',
      key: 'name',
      filteredValue: [filter?.searchText || ""],
      onFilter: (value, record) => {
        return String(record.item?.name).toLowerCase().includes(value.toString().toLowerCase());
      },
      
      render: (text, record) => {
        return <span style={{cursor: "pointer"}}>{text}</span>;
      },
      
       
    },
    {
      title: t('Unit'),
      dataIndex: 'unitOfMeasurement',
      key: 'unit',
      render: (text, record) => {
        return <div>{text}</div>;
      },
    },
    {
      title: t('CurrentStock'),
      dataIndex: 'stock',
      key: 'stock',
      render: (text, record) => {
        return <div>{record.count}</div>;
      },
    },
    {
      title: t('Purchase_price'),
      dataIndex: 'purchasePrice',
      key: 'purchasePrice',
      width: 150,
      align: 'right',
      //sorter: (a, b) => a.count - b.count,
      render: (text, record) => (
        <div>
          {record.item?.price
            ? record.item?.price.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") + "€"
            : "-"}
        </div>
      ),
    },
  ];



  useEffect(() => {
    const fetchMovements = async () => {
      setLoading(true);
      try {

        var date = new Date();
        const response = await waregouseService.getWithAmount(
            AuthService.getCurrentOrganisation().id);
        setData(response);
        console.log("response1231231312312313:", response)
      } catch (error:any) {
        console.error('Ошибка при получении задач:', error);
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
          history.push("/login");
          window.location.reload();

        }
        if(error.response.status===404){
          console.log("notFound")
        }
       
        
        
        
      } finally {
        setLoading(false);
      }
    };
    fetchMovements();
  }, []);


  return (
    <Table
      locale={{
        triggerDesc: t('Kliknite_za_razvrščanje_padajoče'),
        triggerAsc: t('Kliknite_za_razvrščanje_naraščajoče'),
        cancelSort: t('Prekliči_razvrščanje'),
      }}
      columns={columns}
      dataSource={data}
      loading={loading}
      scroll={{ y: 'calc(57vh)' }}
      rowClassName={"rowpointer"}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            history.push("/warehouse/" + record.integrationSpecificId);
          },
        };
      }}
    />
  );
};

export default CommonTable;
