import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {useTranslation} from "react-i18next";
import {Button, Divider, Drawer, Modal, Select, Space, Spin} from "antd";
import ProductDetailPage from "../../Products/Components/ProductDetails";
import Product from "../../../types/Product";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import {ExclamationCircleFilled, PlusOutlined, PrinterOutlined} from '@ant-design/icons';
import {DATA_URL} from "../../../Services/auth-service";
import InvoiceItem from "../../../types/invoiceItem";
import axiosInstance from "../../../utils/axiosInstance";



type DocProps = {
    defaultSelected? : string;
    afterCreate?: (data: any) => void;
    disabled? : boolean
};

const ProductSelect = ({defaultSelected, afterCreate, disabled }: DocProps) => {
    const {t} = useTranslation();


    const history = useHistory();
    const user = AuthService.getCurrentUser();

    const userOrganisation = AuthService.getCurrentOrganisation();



 
    
    
    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
        console.log("Product select handle change: ", value)
        setSelectedProduct(value.toString());
        var prod=productList?.find(f=>f.integrationSpecificId?.toString()===value);
        if(afterCreate) {
            console.log("ProductSelect - prod for afterCreate:", prod)
            afterCreate(prod);
        }
    };

    const [openDrawer, setopenDrawer] = useState(false)

    const[productList, setProductList]=useState<Product[]|null>(null)
    const[selectedProduct, setSelectedProduct] = useState<string|null|undefined>(undefined);
    const[pageloading, setpageloading] = useState<boolean>(false)



    useEffect(()=>{
        if(productList===null){
            console.log("defaultSelected: ", defaultSelected)
            setpageloading(true)
            axiosInstance.get(AuthService.DATA_URL + "integration/item/"+userOrganisation.id+"?", 
                {headers: authHeader()}
            )
                .then((response)=>{
                    console.log(response.data)
                    console.log("defaultSelected: ", defaultSelected)
                    setProductList(response.data.data);
                    if(defaultSelected){
                        setSelectedProduct(defaultSelected.toString());
                    }
                    setpageloading(false)
                })
                .catch(function (error) {
                    setProductList([]);
                    setpageloading(false)

                    if(error.response.status===401){
                        //AuthService.logout();
                        //history.push("/login");
                        //window.location.reload();

                    }
                    if(error.response.status===404){
                        console.log("notFound")
                    }

                })
            
        }
    })


    const onSearch = (value: string) => {
        //console.log('search:', value);
    };

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const createNew=()=>{
        setopenDrawer(true)
    }

    const setnewId=(newProd: Product)=>{
        
        console.log("setong mew id with newProd: ", newProd)
        
        
        if(afterCreate){
            productList?.push(newProd);
            setSelectedProduct(newProd.integrationSpecificId?.toString())
            console.log("ProductSelect - newProd for afterCreate:", newProd)

            afterCreate(newProd);

        }
        else {
            console.log("newProd: ", newProd)
        }
        setopenDrawer(false)
    }



    return <Spin spinning={pageloading} >
        <Select
            showSearch
            //style={{ width: 120 }}
            disabled={disabled}
            optionFilterProp="children"
            onChange={handleChange}
            onSearch={onSearch}
            filterOption={filterOption}
            value={selectedProduct}
            
            dropdownRender={(menu) => (
                <>
                {menu}
                    <Divider style={{ margin: '8px 0' }}/>
                    <Space style={{ padding: '0 8px 4px' }}>
                 <a onClick={()=>{createNew()}}> <PlusOutlined/> Add new good or service</a> 
            </Space>
                </>)}
            
            
            options={productList? productList.map((tr, index) => ({
                label : tr.name ? tr.name : "",
                value : tr.integrationSpecificId?.toString()? tr.integrationSpecificId?.toString() : ""
            }) ): [{value: "", label: ""}]}
        />

        <Drawer title={t("Ustvarite_novo_podjetje")} placement="right"
                onClose={()=>setopenDrawer(false)}
                open={openDrawer}>
            <ProductDetailPage key={0} createId={"0"} aftercreate={setnewId}  />
        </Drawer>
    </Spin>

};
export default ProductSelect;