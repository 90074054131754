import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    List,
    message,
    Modal,
    Row,
    Space,
    Tabs,
    Switch,
    Tooltip,
    Dropdown,
    Divider
} from "antd";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import {Link, useHistory, useParams} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {useTranslation} from "react-i18next";

import { ExclamationCircleFilled } from '@ant-design/icons';
import DealsFilter from "../../../types/DealsListFilter";
import TransactionsFilter from "../../../types/TransactionsListFilter";
import minimaxOrganization from "../../../types/minimaxOrganization";
import OrganisationDTO from "../../../types/OrganisationDTO";
import {subscribe, unsubscribe} from "../../../CommonComponents/Notifications/NotificationContext";


interface EditContractorProps{
    paramValue?: string;
    afterCreate?: (data: any) => string;
}

interface Params {
    id: string;
}

export default function EditContractorComp({ paramValue, afterCreate} : EditContractorProps) {

    const history= useHistory();

    const {t} = useTranslation();

    const {id} = useParams<Params>();

    const user = AuthService.getCurrentUser();
    if(!user){
        history.push("/login");
    }

    const userOrganisation = AuthService.getCurrentOrganisation();


    const str=AuthService.DATA_URL+"integration/customer/"+userOrganisation.id+"/1?taxNumber="+id
    
    console.log("str: ", str)





    const [form] = Form.useForm();

    const [company, setCompany] = useState<OrganisationDTO>();
    const  [ownCompanyId, setownCompanyId] = useState<any>();

    const  [logoId, setlogoId] = useState<any>();
    const  [zigId, setzigId] = useState<any>();
    const  [podpisId, setpodpisId] = useState<any>();

    const[isClientVal, setisClientVal]=useState(false);
    const[isClientValDisabled, setisClientValDisabled]=useState(false);

    const[componentDisabled, setComponentDisabled]=useState(false);

    const [dealsFilter, setdealsFilter]=useState<DealsFilter>({
        searchText: "",
        dateFrom: "",
        dateTo: "",
        hidefinished:true,
        requestStatus: 100,
        selectedDates: "",
        counterPartId : paramValue
    })


    const [transactionFilter, settransactionFilter]=useState<TransactionsFilter>({
        searchText: "",
        dateFrom: "",
        dateTo: "",
        hidefinished:true,
        selectedDates: "",
        counterPartId : paramValue
    })


    useEffect(() => {
        subscribe("OrganizationChange", (data: any) => history.push("/counterparts")  )
        axios.get(AuthService.DATA_URL+"integration/customer/"+userOrganisation.id+"/?taxNumber="+id
            , { headers: authHeader() })
            .then((response) => {
                console.log("response.data: ", response.data.data[0])
                setCompany(response.data.data[0]);
                setownCompanyId(response.data.id);
                setlogoId(response.data.logoId);
                setzigId(response.data.zigId);
                setpodpisId(response.data.podpisId)
                form.setFieldsValue({
                    title: response.data ? response.data.data[0].name : "",
                    davcnaStevilka: response.data ? response.data.data[0].taxNumber : "",
                    adress1: response.data.data[0] ? response.data.data[0].address? response.data.data[0].address.street : "" : "",
                    adress2: response.data.data[0] ? response.data.data[0].address? response.data.data[0].address.city : "" : "",

                    zipCode: response.data.data[0] ? response.data.data[0].address? response.data.data[0].address.zipCode : "" : "",
                    
                    iban: response.data ? response.data.iban : "",
                    swift: response.data ? response.data.swift : "",
                    contactPerson : response.data ? response.data.contactPerson : "",

                });

            })

        return () => {
            unsubscribe("OrganizationChange", (data: any) => history.push("/counterparts") );
        }

    }, [paramValue]);




    const BackToList = () =>{

        if(afterCreate){
            afterCreate("");
        }
        else{
            history.push("/counterparts");
        }
    }

    const onFinish =(values : any)=>{
        console.log("values: ", values)

        axios.put(AuthService.DATA_URL+ "integration/customer/"+userOrganisation.id+"/"+id,
            {

                "integrationSpecificId": company?.integrationSpecificId,
                "name": values.title,
                "taxNumber": values.davcnaStevilka,
                "address":{
                    "street": values.adress1,
                    "city": values.adress2,
                    "zipCode": values.zipCode,
                },
                "iban": values.iban,
                "swift": values.swift,
                "billingEmail": values.email,


            },{headers: authHeader()})
            .then((res) =>{
                    console.log("after pit res: ", res)
                    if(afterCreate){
                        afterCreate(res);
                    }
                    else{
                        history.push("/counterparts");
                    }
                }
            );



    }

    const { TabPane } = Tabs;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalCancel = () => {
        setreload(!reload);
        setIsModalOpen(false);
    };
    const showModal1 = () => {
        setIsModalOpen(true);

    };

    const [reload, setreload] = useState(false);

    const handleModalOk=()=>{
        setreload(!reload);
        setIsModalOpen(false);
    }




    const showval=(v: any)=>{
        console.log("v: ",v);
        console.log("FieldValue: ",form.getFieldValue("isClient"))
        setisClientVal(v);

    }


    const handleDelete=()=>{
        const { confirm } = Modal;
        confirm({
            title: t('Delete_warning'),
            icon: <ExclamationCircleFilled />,
            content: t("Delete_text"),
            okText: t('Yes'),
            okType: 'danger',
            cancelText: t('No'),
            onOk() {
                axios.delete(AuthService.DATA_URL+'Contractor/'+company?.integrationSpecificId
                    ,{headers: authHeader()})
                    .then((res:any) =>{
                            console.log(res)
                            if(res.status.toString()==="204"){
                                Modal.error({
                                    title: t("Delete_error_title"),
                                    content: t("Contractor_delete_error_text"),
                                });
                            }
                            else{
                                message.success(t("Done"));
                                history.push("/counterparts");
                            }
                        }
                    );


            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    if(company===undefined){
        return (<div> Loadng...</div>);
    }
    return (
        <div style={{width: "100%"}}>
            <h1>{t("Podatki_o_partnerju")}{company? ": "+company.name : ""}</h1>

            <Form
                form={form}
                name="wrap"
                onFinish={onFinish}
                autoComplete="off"
                layout="horizontal"
                labelCol={{ flex: '130px' }}
                labelAlign="right"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
                disabled={componentDisabled}

            >




                <Row>
                    <Divider/>
                    <Col span={afterCreate? 24 : 8}>


                        <Form.Item
                            name="title"
                            label={t("Naziv")}
                            rules={[{ required: true }, { type: 'string', warningOnly: true }]}
                        >
                            <Input className={componentDisabled? "showformelem" : ""} placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item
                            name="davcnaStevilka"
                            label={t("Davčna_Številka")}
                            rules={[{ required: true, type: 'string' }]}
                        >
                            <Input  className={componentDisabled? "showformelem" : ""} placeholder="" />
                        </Form.Item>

                        <Form.Item
                            name="Email"
                            label={t("E_pošta")}
                            rules={[{ type: 'string', warningOnly: true }]}
                        >
                            <Input  className={componentDisabled? "showformelem" : ""} placeholder="" />
                        </Form.Item>
                        <Form.Item
                            name="adress1"
                            label={t("Naslov")}
                            rules={[{ required: true, type: 'string'  }]}
                        >
                            <Input  className={componentDisabled? "showformelem" : ""} placeholder={t("Street_and_house_number")} />
                        </Form.Item>
                        <Form.Item
                            name="adress2"
                            label={t("City")}
                            rules={[{ required: true, type: 'string'  }]}
                        >
                            <Input  className={componentDisabled? "showformelem" : ""} placeholder="" />
                        </Form.Item>
                        <Form.Item
                            name="zipCode"
                            label={t("ZipCode")}
                            rules={[{ type: 'string', warningOnly: true }]}
                        >
                            <Input  className={componentDisabled? "showformelem" : ""} placeholder="" />
                        </Form.Item>


                    </Col>
                    <Col span={afterCreate? 24 : 8}>
                        <Form.Item
                            name="iban"
                            label="IBAN"
                        >
                            <Input  className={componentDisabled? "showformelem" : ""} placeholder="SI56123412341234123"  />
                        </Form.Item>
                        <Form.Item
                            name="swift"
                            label="SWIFT"
                        >
                            <Input  className={componentDisabled? "showformelem" : ""} placeholder="LJAASI1A"  />
                        </Form.Item>

                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {componentDisabled?
                            <span>
                                        <br/>
                                        <Link className={"actionLink"} to={`/counterparts`}>{t("BackToList")}</Link>

 
                                    </span>

                            :
                            <span>
                                <Form.Item>
                                    <Space>
                                        <Button className={"orangeButton"} htmlType="submit" disabled={true}>
                                            {t("Shraniti")}
                                        </Button>
                                        <Button className={"emptyButton"} onClick={BackToList} >
                                            {t("Ne_shranite_sprememb")}
                                        </Button>
                                            {afterCreate?
                                                ""
                                                :
                                                <Dropdown menu={{'items': [{ key: '1',  label: <Button type="primary" danger>{t("Delete")}</Button> }], onClick: handleDelete }} >
                                                    <Button type="text">{t("More")}..</Button>
                                                </Dropdown>
                                            }
                                        </Space>
                                </Form.Item>
                            </span>

                        }

                    </Col>
                </Row>
            </Form>




        </div>



    )}