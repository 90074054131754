import React, {useContext, useState} from 'react';

import './App.css';
import {useHistory, withRouter} from "react-router-dom";


import * as AuthService from "../src/Services/auth-service";
import {Divider, Layout, Menu, theme, ConfigProvider} from 'antd';
import { ArrowDownOutlined, LogoutOutlined, FileTextOutlined, GoldOutlined, IdcardOutlined, SettingOutlined, HomeOutlined } from '@ant-design/icons';
import {Routing} from "./Pages/app-router";
import {calc} from "antd/es/theme/internal";
import SideMenu from "./CommonComponents/Sidermenu";
import AppHeader from "./CommonComponents/HeaderComponent";
import { NotificationContext } from './CommonComponents/Notifications/NotificationContext';
import axios from "axios/index";
import authHeader from "./Services/auth-header";
import {DATA_URL} from "../src/Services/auth-service";



import { Grid } from "antd";


function App() {

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const { useBreakpoint } = Grid;
    const breakpoints = useBreakpoint();

    
    const { Header, Content, Footer, Sider } = Layout;
  
    const user = AuthService.getCurrentUser();
    const orgs = AuthService.getUsersOrganisations();

    const [currentOrganizationId, setcurrentOrganizationId] =  useState("");
    const contextValue = { currentOrganizationId, setcurrentOrganizationId };

    const [collapsed, setCollapsed] = useState(false);







    console.log("app.tsx user: ", user);
    console.log("app.tsx orgs: ", orgs);
    const history= useHistory();

    if(!user){
        //history.push("/login");
        //window.location.reload();
    }
    


    function ContentDiv() {
        return <div>
            <Routing/>
        </div>;
    }


  // @ts-ignore
    return (
    <div className="App">
        <ConfigProvider
            theme={{
                token: {
                    // Seed Token
                    colorPrimary: '#265a96',
                    colorLink: '#4640de',
                    

                    //borderRadius: 2,

                    // Alias Token
                    //colorBgContainer: '#f6ffed',
                },
                components: {
                    Layout: {
                       // siderBg: 'transparent',
                    },
                    Menu: {
                        //itemBg: '#fff',
                        //itemActiveBg : '#4640de',
                    },
                }
            }}>
  
          <div>
              {user?
                  <NotificationContext.Provider value={{currentOrganizationId: "",
                      setcurrentOrganizationId: () => {}}}>

   
                      
                      
                     
                  <Layout hasSider>
                      <Sider className={"siderbg"}
                             breakpoint="lg"
                             width={250}
                             //collapsible
                             //collapsedWidth="0"
                             onBreakpoint={(broken) => {
                                // console.log(broken);
                             }}

                             collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}
                             
                             
                             //onCollapse={(collapsed, type) => {
                                // console.log(collapsed, type);
                             //}}


                             style={{ overflow: 'auto', height: "100vh", position: 'fixed', left: 0, top: 0, bottom: 0 }}
                      >
                          <div style={{ height: "54px", textAlign: "center"}}>
                              <img
                                  style={{width: breakpoints.lg? "100px" : "40px", margin: breakpoints.lg? "15px 20px" : "15px 11px"}}
                                  src={ breakpoints.lg?  "/img/logo.png" : "/img/logo_m.png"}/>


                          </div>

                          <SideMenu/>

                      </Sider>

                      <Layout style={{ marginLeft: breakpoints.lg? 250 : 60  }}>
                          <Header style={{ padding: 0, background: colorBgContainer }} >
                              <AppHeader/>
                              
                          </Header>
                          <Content style={{ //margin: '0 16px', 
                              paddingLeft: "16px",
                              overflow: 'initial', 
                              minHeight: 'calc(100vh - 64px)' }}>

                              <div
                                  style={{
                                      //background: colorBgContainer,
                                      minHeight: 280,
                                      padding: 24,
                                      //borderRadius: borderRadiusLG,
                                  }}
                              >
                                  <ContentDiv/>
                              </div>
                          </Content>

                      </Layout>
                  </Layout>
                      
                  </NotificationContext.Provider>
                  :

                  <Content style={{ /*margin: '24px 16px 0',*/ overflow: 'initial' }}>

                      <div
                          style={{
                              //background: colorBgContainer,
                              minHeight: 280,
                              //padding: 24,
                              //borderRadius: borderRadiusLG,
                          }}
                      >
                          <ContentDiv/>
                      </div>
                  </Content>



              }
          
                

          </div>
        </ConfigProvider>
          
          
          

      
      
    </div>
  );
}

export default withRouter(App);
