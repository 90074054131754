import React, { useState} from 'react';
import {useHistory} from "react-router-dom";
import {Grid, Menu} from "antd";
import {
    FileTextOutlined,
    GoldOutlined,
    HomeOutlined,
    IdcardOutlined,
    LogoutOutlined,
    SettingOutlined,
    TableOutlined
} from "@ant-design/icons";
import {useTranslation} from "react-i18next";



const SideMenu = ()=>{

    const {t} = useTranslation();
    const history= useHistory();

    const { useBreakpoint } = Grid;
    const breakpoints = useBreakpoint();

    const locationString = window.location;

    const [current, setCurrent] = useState(locationString.pathname);

    function handleClick(e: any) {
        setCurrent(e.key);
    }
    
    return <Menu
        theme="dark"
        mode="inline"
        //defaultSelectedKeys={['2']}
        //items={lpMenuitems}
        //style={{ flex: 1,  minWidth: 0 }}
        onClick={(key) => {
            handleClick(key);

            history.push(key.key);
        }}
        items={
            [
                {
                    key: '1',
                    type: 'divider',
                },
                {
                    key: "/dashboard",
                    label: t("Domov"),
                    title: "",
                    icon: <HomeOutlined  style={{fontSize: '18px'}}/>
                },


                {
                    key: "/docs",
                    label: t("Dokumenti"),
                    title: "",
                    icon: <FileTextOutlined  style={{fontSize: '18px'}}/>
                },


                {
                    key: "/products",
                    label: t("Izdelki"),
                    title: "",
                    icon:  <GoldOutlined style={{fontSize: '18px'}}/>
                },
                {
                    key: "/counterparts",
                    label: t("Stranke"),
                    title: "",
                    icon: <IdcardOutlined  style={{fontSize: '18px'}}/>
                },
                breakpoints.lg || breakpoints.xl ?  {
                    key: '/warehouse',
                    icon: <TableOutlined style={{fontSize: '18px'}} />,
                    label: t("Warehouse"),
                }: null,
                {
                    key: '/settings',
                    title: "",
                    icon: <SettingOutlined  style={{fontSize: '18px'}} />,
                    label: t("Nastavitve"),
                },
                

                {
                    key: '/logout',
                    title: "",
                    //onClick :(logOut),
                    icon: <LogoutOutlined  style={{fontSize: '18px'}}/>, //<img src="/img/logout_icon.png" alt={" "}
                    // style={{float: "left", height: '15px', margin: '10px 0px 10px 6px'}}/>,
                    label: t("Odjava"),//t('Odjava'),
                    style : {
                        position: 'absolute',
                        bottom: 20,
                        zIndex: 1,
                        transition: 'all 0.2s',
                    }
                }

            ]

        }



    />
    
}

export default SideMenu