import React, {useEffect, useState} from 'react';
import {
    Affix,
    Button,
    Col,
    ConfigProvider,
    DatePicker,
    Divider, Dropdown,
    Form,
    Input,
    InputNumber, MenuProps, message, Modal,
    notification,
    Row,
    Select,
    Space, Spin
} from 'antd';
import axios from 'axios';
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {waregouseService} from "../../../Services/warehouseService";
import dayjs from "dayjs";
import * as AuthService from "../../../Services/auth-service";
import ProductSelect from "../../Docs/Components/ProductSelect";
import {ExclamationCircleFilled, RightOutlined} from "@ant-design/icons";
import authHeader from "../../../Services/auth-header";
import axiosInstance from "../../../utils/axiosInstance";




const { Option } = Select;



interface position {
    rowkey? : string,
    movement?: any
}


const EditMovementPage: React.FC = () => {
    const [form] = Form.useForm();


    const history = useHistory();

    
    const {id} = useParams<{ id?: string }>(); // Получение ID поставщика из URL, если он есть
    const { forproductid } = useParams<{ forproductid?: string }>(); // Получение ID товара из URL, если он есть

    const {docType} = useParams<{ docType?: string }>(); // Получение ID поставщика из URL, если он есть
    console.log("docType: ", docType)


    const {t} = useTranslation();


    const [isLoading, setIsLoading] = useState(false)
    const [positions, setPositions] = useState<position[]>([])
    const [rkey, setRkey] = useState(1)
    
    const [_docType, set_DocType] = useState(docType)
    
    
    const [selectedProductId, setSelectedProductId] = useState<string|undefined>(forproductid);
    const [selectedProductTitle, setSelectedProductTitle] = useState(<>&nbsp;</>);
    
    const [producthelp, setProducthelp] = useState("")
    const [productValidStatus, setProductValidStatus] = useState("")


//    const [selectedFromWarehouseId, setSelectedFromWarehouseId] = useState<string | undefined>()
//    const [selectedToWarehouseId, setSelectedToWarehouseId] = useState<string | undefined>()
    const [selectedDate, setSelectedDate] = useState<string | undefined>()
    
    useEffect(()=>{
        
        
        const fetchProductName=(product_id: string)=>{
            setIsLoading(true)
            axiosInstance.get(AuthService.DATA_URL + 'integration/item/'+AuthService.getCurrentOrganisation().id+"/id/" + product_id,
                {headers: authHeader()})
                .then((response) => {
                    console.log(response)
                    setSelectedProductTitle(
                        <>
                        <span style={{color: "#999", cursor: "pointer", borderBottom: "1px dashed #999"}} onClick={()=> history.push("/warehouse")}>
                            {t("Warehouse")}
                        </span>

                            { forproductid || id ?
                                <span>
                &nbsp;<RightOutlined size={16} style={{fontSize:"18px"}} />&nbsp;

                                    <span style={{color: "#999", cursor: "pointer", borderBottom: "1px dashed #999"}} onClick={()=> history.push("/warehouse/"+(product_id ))}>
                {response.data.name}
            </span>
                </span>
                                :
                                ""}




                            <span style={{}}>
                    &nbsp;<RightOutlined size={16} style={{fontSize:"18px"}} />&nbsp;
                                {id?
                                    t("Edit_movement")

                                    : t("New_movement")}
                     </span>









                        </>  
                        
                    )
                    setIsLoading(false)
                })
                .catch((error)=>{
                    if (error.response.status === 401) {
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                    if(error.response.status===404){
                        console.log("notFound")
                    }
                })
            
        }
        
        if(forproductid){
            fetchProductName(forproductid)
        }
        if (id) {
            setIsLoading(true);

            waregouseService.getById(AuthService.getCurrentOrganisation().id, id)
                .then((document) => {
                    console.log("document: ", document)

                    setSelectedProductId(document.integrationInvoiceItemId);
                    fetchProductName(document.integrationInvoiceItemId ? document.integrationInvoiceItemId : "")

                    const stt = [document].map(doc => ({
                        ...doc,
                        movementDate: doc.movementDate ? dayjs(doc.movementDate) : undefined,
                        name: doc.name,
                        //invoiceDate:  doc.invoiceDate ? dayjs(doc.invoiceDate ) : undefined,
                        //orderDate:  doc.orderDate ? dayjs(doc.orderDate ) : undefined,
                        //paymentDate:  doc.paymentDate ? dayjs(doc.paymentDate ) : undefined,
                        movementType: doc.movementType

                    }))[0];

                    set_DocType(document.movementType?.toString())


                    //var docch=document;
                    //docch.documentDate=dayjs(document.documentDate).toISOString()

                    
                    form.setFieldsValue(stt);
                })
                .catch((error)=>{
                    if (error.response.status === 401) {
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                    if(error.response.status===404){
                        console.log("notFound")
                    }
                })
        }
        else{
            setSelectedProductTitle(
                <>
                        <span style={{color: "#999", cursor: "pointer", borderBottom: "1px dashed #999"}} onClick={()=> history.push("/warehouse")}>
                          {t("Warehouse")}
                        </span>
                    <span style={{}}>
                    &nbsp;<RightOutlined size={16} style={{fontSize:"18px"}} />&nbsp;
                        {id?
                            t("Edit_movement")

                            : t("New_movement")}
                     </span>
                    </>
            )
        }
        },[])
  
    
    
    const handleProductSelect =(e: any)=>{
      setSelectedProductId(e.integrationSpecificId);
      setSelectedProductTitle(e.name)
        //form.setFieldValue("product", e.integrationSpecificId);
        setProducthelp("")
        setProductValidStatus("")
        
    }

    const handleDateSelect =(e: Date)=>{
        setSelectedDate(e.toUTCString)
    }



    const handleSubmit = async (values: any)=>{


        try {
            if (id) {
                const entity = {
                    name: values.name,
                    movementType: values.movementType,
                    movementDate: new Date(values.movementDate).toISOString(),
                    quantity: values.quantity,
                    price: values.price ? values.price : 0,
                    clientName: values.clientName  ? values.clientName : "",
                    //returnDate: "2024-09-24T14:42:11.709Z"
                }
                
                
                
                console.log("put")
                const response = await waregouseService.update(id, entity)
                notification.success({ message: 'Movement updated successfully!' });
                history.push("/warehouse/"+selectedProductId)


            } else {
                const entity = {
                    name: values.name,
                    //organizationId: AuthService.getCurrentOrganisation().id,
                    integrationInvoiceItemId: selectedProductId,
                    movementType: values.movementType,
                    movementDate: new Date(values.movementDate).toISOString(),
                    quantity: values.quantity,
                    price: values.price  ? values.price : 0,
                    clientName: values.clientName ? values.clientName : "",
                    //returnDate: "2024-09-24T14:42:11.709Z"
                }
                
                
                const response = await waregouseService.create(entity)
                notification.success({ message: 'Movement created successfully!' });
                history.push("/warehouse"+(forproductid? "/"+forproductid : ""))
            }
        } catch (error) {
        console.error('Ошибка при получении перемещений:', error);
        notification.error({ message: 'Error creating movement' });

    } finally {

    }
    }




    const handleDelete= async ()=>{
        const { confirm } = Modal;
        confirm({
            title: t('Delete_warning'),
            icon: <ExclamationCircleFilled />,
            content: t("delete_movement_text"),
            okText: t('Yes'),
            okType: 'danger',
            cancelText: t('No'),
            onOk() {

                try {
                    const response = waregouseService.delete(id? id : "").then(()=>{
                        notification.success({ message: 'Movement deleted successfully!' });
                        history.push("/warehouse/"+selectedProductId)
                    });
                    

                }
                catch (error) {
                    console.error('Ошибка при удалении перемещений:', error);
                    notification.error({ message: 'Error deleting  movement' });

                } finally {

                }


            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }



    const validateMessages = {
        required: "",
        // ...
    };
    
    return <Spin spinning={isLoading}>

        
        <h1> 
            {selectedProductTitle}
        </h1>
     
        <ConfigProvider
            theme={{
                components: {
                    Form: {
                        itemMarginBottom: 10
                    },
                },
            }}
        >
        <Form  validateMessages={validateMessages}
            form={form}
            layout="horizontal"
            labelCol={{ flex: '100px' }}
            labelWrap={true}
            //onChange={handleDocChanges}
            onFinish={handleSubmit}
            initialValues={{
               // documentType: InventoryDocumentType.Prevzemnica,
            }}
        >

            
            <Row>
                <Col span={12}>
                    <Form.Item
                        label={t("Naziv")}
                        name="name"
                        rules={[{required: true}]}

                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            
            <Row>
                <Col span={12}>
                    <Form.Item
                        label={t("goods")}
                        name="product"
                        //rules={[{required: true}]}
                        validateStatus={selectedProductId? "success" : "error"}

                    >
                        <ProductSelect defaultSelected={forproductid? forproductid : selectedProductId} disabled={id ? true : false}
                        afterCreate={(v)=>handleProductSelect(v)}
                        />
                    </Form.Item>

                </Col>

                <Col span={6}>
                    <Form.Item
                        label={t("Vrsta")}
                        name="movementType"
                        rules={[{required: true}]}

                    >
                        <Select options={[
                            {value:"purchase", label:t("purchase")},
                            {value:"production", label:t("production")},
                            {value:"sale", label:t("sale")},
                            {value:"rental_out", label:t("rental_out")},
                            {value:"rental_return", label:t("rental_return")},
                            {value:"write_off", label:t("write_off")},
                        ]} />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item
                        label={t("Movement_date")}
                        name="movementDate"
                        rules={[{required: true}]}

                        // rules={[{ required: true, message: 'Выберите дату документа' }]}  
                    >
                        <DatePicker placeholder={""} format="DD.MM.YYYY" />
                    </Form.Item>

                </Col>

            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item
                        label={t("Stranka")}
                        name="clientName"
                        rules={[{required: true}]}

                    >
                        <Input />
                    </Form.Item>

                </Col>

                <Col span={6}>
                    <Form.Item
                        label={t("Količina")}
                        name="quantity"
                        rules={[{required: true}]}

                    >
                        <InputNumber style={{width: "100%"}} placeholder={t("Količina")}  step={0.01} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={t("Cena")}
                        name="price"
                    >
                        <InputNumber style={{width: "100%"}} placeholder={t("Količina")}  step={0.01} />
                    </Form.Item>
                </Col>


            </Row>
        <Divider />
            <div  style={{float: "right"}}>
                <Space>
                    <Form.Item >
                        <Button type={"link"}
                        onClick={()=>{history.push("/warehouse"+(id || forproductid ? "/"+selectedProductId : ""))}}
                        >&larr; {t("Ne_shranite_sprememb")}</Button>
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType={"submit"} type={"primary"}>{t("Shraniti")}</Button>
                    </Form.Item>


                    {id?

                        <Form.Item>
                            <Dropdown menu={{'items': [{ key: '1',  label: <Button type="primary" danger>{t("Delete")}</Button> }], onClick: handleDelete }} >

                            <Button type="text">{t("More")}..</Button>
                        </Dropdown>
                        </Form.Item>

                        :""
                    
                    }

                </Space>
                
                
            </div>










        </Form>
        </ConfigProvider>
    
    </Spin>
}

export default EditMovementPage;

