import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../Services/auth-service";
import {Row, Space} from "antd";
import OrganizationSelect from "./OrganizationSelect";
import BreadCrumbsComp from "./BreadCrumbsComp";

import { Grid } from "antd";


const AppHeader = () => {
    const history= useHistory();
    const user = AuthService.getCurrentUser();
    console.log("AppHeader user 334443434443: ", user)

    const { useBreakpoint } = Grid;
    const breakpoints = useBreakpoint();
    

    if(!user){
        //history.push("/login");
    }

    console.log("breakpoints 336723654234:", breakpoints) 
    return <div style={{margin: "0px 20px"}}>

        {breakpoints.lg  ?
            <Space style={{float: "left",  height: '64px'}}>
                <BreadCrumbsComp />
            </Space>

            :""
        }
 
<Space style={{float: "right",  
}}>        
    <div style={{float: "right"}}>
        <Space>
        <OrganizationSelect />
        
        
        { breakpoints.lg  ? 
            <> <span> | </span> {user.user?.firstName + " " + user.user?.lastName}</>  
            : ""
        }
        </Space>
    </div>
</Space>
    </div>

}

export default AppHeader