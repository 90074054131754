import React, {useContext, useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import Product from "../../../types/Product";
import axios from "axios";
import {DATA_URL} from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import Organization from "../../../types/Organization";
import { ColumnsType } from 'antd/es/table';
import {useTranslation} from "react-i18next";
import CommonListFilter from "../../../types/CommonListFilter";
import contractor from "../../../types/contractor";
import {Grid, List, Spin, Table} from "antd";
import minimaxOrganization from "../../../types/minimaxOrganization";
import OrganisationDTO from "../../../types/OrganisationDTO";
import {NotificationContext, subscribe, unsubscribe} from "../../../CommonComponents/Notifications/NotificationContext";




interface counterPartListProps{
    filter : CommonListFilter
}

const CounterPartsTable = ({ filter} : counterPartListProps) => {
    const history= useHistory();
    const user = AuthService.getCurrentUser();


    const { useBreakpoint } = Grid;
    const breakpoints = useBreakpoint();

    if(!user){
        //history.push("/login");
    }
    const {t} = useTranslation();

    const [userOrganisation, setuserOrganisation] = useState(AuthService.getCurrentOrganisation().id);
    const [counterParts, setcounterParts] = useState<OrganisationDTO[]|null>(null)
    const[pageloading, setpageloading] = useState<boolean>(false)


    const changeOrg=(id: string)=>{

        setuserOrganisation(id)
        getCounterparts(id)
    }
    
    console.log("counterParts: ", counterParts)
    
    
    const getCounterparts = (id: string)=>{
        
        setpageloading(true)

        setcounterParts([]);

        axios.get(DATA_URL + "integration/customer/"+id+"/?page=1",
            { headers: authHeader()
            })
            .then((res)=>{
                console.log("res.data: ",res.data)
                setcounterParts(res.data.data)
                setpageloading(false)

            })
            .catch(function (error) {
                setcounterParts([]);
                setpageloading(false)

                if(error.response.status===401){
                    AuthService.logout();
                    history.push("/login");
                    window.location.reload();

                }
            })
    }
    
    
    
    
    useEffect(()=>{
        subscribe("OrganizationChange", (data: any) => changeOrg(data.detail) /* console.log("changed: ", data.detail)*/ )
        if(counterParts===null){

            getCounterparts(userOrganisation)

        }
        return () => {
            unsubscribe("OrganizationChange", (data: any) => changeOrg(data.detail));
        }


    }, [])


    const columns: ColumnsType<OrganisationDTO> = [
        {
            //    key: 'Naziv',
            title: t('Naziv'),
            dataIndex: 'name',
            filteredValue :[filter? filter.searchText? filter.searchText : "" : ""],
            onFilter: (value, record) => {
                return (
                    String(record.name).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.taxNumber).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.address?.street ).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.address?.street ).toLowerCase().includes(value.toString().toLowerCase())
                )
            },
            ellipsis: true,
        },
        /* 
        {
            title: t(' '),
            dataIndex: 'isClient',
            width: 110,
            ellipsis: true,
            render: (text, record) =>
                <span style={{color: "#bbb"}}>
                {record.isClient? t("Stranka"): t("Izvajalec")}                    
                </span>

        },
        */
        {
            title: t('Davčna_Številka'),
            dataIndex: 'taxNumber',
            width: 160,

            //      key: 'davcnaStevilka',
            //      sorter: (a, b) => a.davcnaStevilka.length - b.davcnaStevilka.length,
            //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
            ellipsis: true,

        },
        /*
        {
            title: t('Matična_Številka'),
            dataIndex: 'maticnaStevilka',
            width: 160,

            //        key: 'maticnaStevilka',
            //        sorter: (a, b) => a.maticnaStevilka.length - b.maticnaStevilka.length,
            //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
            ellipsis: true,

        },
        
         */
        {
            title: t('Naslov'),
            dataIndex: 'Address',
            //          key: 'adress',
            //          sorter: (a, b) => a.maticnaStevilka.length - b.maticnaStevilka.length,
            //sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
            ellipsis: true,
            render: (text, record) =>
                
                /*(record.address?.houseNumber? record.address?.houseNumber : "")+" "+ */
                
                record.address?.street+" "+record.address?.zipCode+" "+record.address?.city ,

        },
        {
            title: ' ',
//            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (text, record) =>
                <a className={"actionLink"} href={"/counterparts/editcontractor/"+record.taxNumber}>{t("Več")} &rarr;</a>,
        }]






    return  <Spin spinning={pageloading}>

        {(breakpoints.lg || breakpoints.xl) ?
            <Table
                className="ContractorsTable"
                locale={{
                    triggerDesc: 'Нажмите, чтобы отсортировать по убыванию',
                    triggerAsc: 'Нажмите, чтобы отсортировать по возрастанию',
                    cancelSort: 'Отменить сортировку'
                }}
                columns={columns}
                dataSource={counterParts ? counterParts : []}
                pagination={{pageSize: 50}} size="small" scroll={{y: 'calc(60vh - 4em)'}}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            history.push("/counterparts/editcontractor/" + record.taxNumber)
                        }, // click row
                    };
                }}
            />
            :

            <div style={{height: 'calc(67vh)', overflowY: "scroll"}}>
                <List
                    loading={pageloading}

                    dataSource={counterParts ? counterParts : []}
                    renderItem={(item) => (
                        <List.Item
                            // actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">more</a>]}
                        >
                            <List.Item.Meta
                                title={
                                    <Link
                                        to={`/counterparts/editcontractor/${item.taxNumber}`}> 
                                            <span >
                                                {item.name ? item.name : "-"}
                                            </span>
                                    </Link>

                            }
                                description={
                                    <div>

                                        <Link
                                            style={{color: "#000"}}
                                            to={`/counterparts/editcontractor/${item.taxNumber}`}> 
                                            <span>
                                                {item.taxNumber}
                                            </span>
                                        </Link><br/>
                                        <span style={{fontSize: "13px", color: "#999"}}>
                                            {item.address?.street+" "+item.address?.zipCode+" "+item.address?.city}
                                        </span>

                                    </div>
                                }
                            />
      
                        </List.Item>
                    )}
                />
            </div>
        }


    </Spin>

}

export default CounterPartsTable