import React, {useEffect, useState} from 'react';
import axios from "axios";
import authHeader from "../Services/auth-header";
import {DATA_URL, loadUsersOrganisations, login} from "../Services/auth-service";
import {Button, Card, Checkbox, Col, ConfigProvider, Divider, Form, Input, message, Row, Space} from "antd";
import {useIsomorphicLayoutEffect} from "@react-pdf-viewer/core";
import {useTranslation} from "react-i18next";
import LangSelect from "../CommonComponents/LangSelect";
import {useHistory} from "react-router-dom";
import * as AuthService from "../Services/auth-service";


type FieldType = {
    username?: string;
    password?: string;
    repassword?: string;
    gdprs?: string;
    readrules? : string;
};



const OnboardingPage = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const inviteCodeValue = urlParams.get('code');
    const emailValue = urlParams.get('email');
    
    
    const {t}=useTranslation();
    const history= useHistory();




    //console.log("inviteCodeValue: ", inviteCodeValue)
    //console.log("emailValue: ", emailValue)
    
    
    const fetchCode = async ():Promise<any>=>{
        try {
            const response = await axios
                .get(DATA_URL+'organization/invite/'+inviteCodeValue
                    , {})
            console.log("response.data: ", response.data)
            return response.data
        }
        catch {
            console.log ("exception");
        return {}
        }
    }


    const createUser = async (values: any, email: string, password: string, firstName: string, lastName: string):Promise<any>=>{
        try {
            console.log(values)
            console.log("email, password, firstName, lastName: ", email, password, values.firstname, values.lastname)
            
            
            const response = await axios
                .post(DATA_URL+'user?activationCode='+inviteCodeValue,
                    {
                        email: email,
                        firstName: values.firstname,
                        lastName:values.lastname,
                        password: password,
                       // role: "business"
                    }
                //    , {headers: authHeader()}
                )
            return response.data
            
             
           // return  ""
        }
        catch(e:any) {
            console.log ("exception", e.code);
            if(e.code==="BAD_REQUEST"){
                message.error('');
            }
            return {}
        }
    }

    
    useEffect(()=>{
        const user = AuthService.getCurrentUser();
        if(user){
            AuthService.logout();
            window.location.reload();
        }



        if(inviteCodeValue){

          // const  checkRes=fetchCode();
           //console.log("checkRes: ", checkRes)
        }
    },[])
    
    
    
    
    const onFinish=(values: any)=>{
        console.log("values: ", values)
        
        const createResult = createUser(values, values.username, values.password, 
            values.firstName, 
            values.lastName).then(()=>{

            console.log("createResult: ", createResult)
            login(values.username, values.password).then(
                () => {
                    loadUsersOrganisations();
                    console.log("localStorage: ", localStorage);
                    history.push("/dashboard");
                    window.location.reload();
                },
                (error) => {
                    //setLoading(false)
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    message.error("Ошибка!");
                    console.log(resMessage);
                    //history.push("/");

                })


        })
    
        
         

        
        
    }

    const onFinishFailed=()=>{}


    return <div style={{width: "100%", height: "100vh", backgroundColor: "#f9f9fb"}}>

        
        <Row>
            <Col span={10} offset={7}>

                <div  style={{marginTop: "5vh"}}>
                    <img
                        style={{width: "100px", margin: "15px 20px"}}
                        src={"/img/logo.svg"}/>
                    <Card extra={<LangSelect/>}>


                        <ConfigProvider
                            theme={{
                                components: {
                                    Form: {
                                        itemMarginBottom: 36
                                        /* here is your component tokens */
                                    },
                                },
                            }}
                        >
                        
                        <Form
                            name="basic"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 16 }}
                            style={{ maxWidth: 600 }}
                            
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            initialValues={{ 
                                username:emailValue,
                               

                            }}
                        >
                            
                          
                                <Form.Item
                                    label={t("Ime")}
                                    help={t("Please_enter_your_First_Name")}
                                    rules={[{ required: true, message: t('Please_enter_your_First_Name')  }]}
                                    name="firstname">
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    help={t("Please_enter_your_Last_Name")}
                                    label={t("Priimek")}
                                    rules={[{ required: true, message: t('Please_enter_your_Last_Name')  }]}
                                    name="lastname">
                                    <Input/>
                                </Form.Item>
                         
                            
                            <Form.Item<FieldType>
                                label={t("Login")}
                                name="username"
                                help={t('Please_input_your_Username')}
                                rules={[{ required: true, message: t('Please_input_your_Username')  }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item<FieldType>
                                label={t("Password")}
                                name="password"
                                help={t('Please_create_your_Password')}
                                rules={[{ required: true,min: 8, message:  t('Please_create_your_Password') }]}
                            >
                                <Input.Password />
                            </Form.Item>



                            <Divider/>
                                    <Form.Item<FieldType>
                                        name="readrules"
                                        //noStyle={true}
                                        valuePropName="checked"
                                        help={t("rules_confirm_help")}
                                        wrapperCol={{ offset: 0, span: 24 }}
                                        rules={[{ required: true}]}
                                    >
                                        <Checkbox>I have read the rules </Checkbox>
                                    </Form.Item>

                                    <Form.Item<FieldType>
                                        name="gdprs"
                                        help={t("privacy_confirm_help")}
                                        valuePropName="checked"
                                        wrapperCol={{ offset: 0, span: 24 }}
                                        rules={[{ required: true}]}
                                    >
                                        <Checkbox>I have read the gdprs</Checkbox>
                                    </Form.Item>

                            


     
                           



                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                            
                        </ConfigProvider>
                            
                    </Card>

                    
                </div>
             


            </Col>

        </Row>
    
    
    </div>


}

export default OnboardingPage