import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import ProdTable from "./Components/ProductTable";
import {Button, Col, Divider, Grid, Row, TableProps} from "antd";
import {useTranslation} from "react-i18next";
import CommonListFilter from "../../types/CommonListFilter";
import ProductListFilter from "./Components/ProductListFilter";

const ProductsList = () => {
    const history= useHistory();
    const user = AuthService.getCurrentUser();

    const { useBreakpoint } = Grid;
    const breakpoints = useBreakpoint();



    console.log("ProductsList user: ", user)

    if(!user){
       // history.push("/login");
    }

    const {t} = useTranslation();

    const [pageFilter, setPageFilter]=useState<CommonListFilter>({
        searchText: "",
        dateFrom: "",
        dateTo: "",
        selectedDates: "",
    })



    
    
    const setCBFilterFunc=(filter: any)=>{
        setPageFilter(filter);
        //console.log("page comp:", filter)
    }







    return <>
       <Row>
           <Col
               xs={20} sm={20} md={14} lg={14} xl={14}
               //span={14}
           >
               <h1>
                   {t("Izdelki")}
               </h1>
           </Col>
           <Col
               xs={22} sm={22} md={5} lg={5} xl={5}
               //span={5} 
               style={{display: "flex", alignItems: "center"}}>
               <Button
                   style={{/*float: "right"*/}}
                   onClick={()=>history.push("products/0")}
                   type={"primary"} >+ {t("add_new_product")}</Button>
           </Col>
       </Row>

        <Divider/>
        <ProdTable filter={pageFilter}/>
    
    </>

}

export default ProductsList