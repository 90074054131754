import React, {useEffect, useState} from 'react';
import {Badge, Button, Checkbox, Col, DatePicker, Grid, Input, Row, Select, Space, Table, Tooltip} from 'antd';
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import DealsFilter from "../../../types/DealsListFilter";




interface FilterProps {
    setFilterFunc?: (values: any) => void;
    pageType: "sentInvoices"| "receivedInvoices";
}

const DocListFilter=({setFilterFunc, pageType}: FilterProps)=>{

    const {t} = useTranslation();


    const { useBreakpoint } = Grid;
    const breakpoints = useBreakpoint();



    const [showFilterBadge, setshowFilterBadge] = useState(false)
    const [showDatesBadge, setshowDatesBadge] = useState(false)
    //const[hideFinished, sethideFinished]=useState(false)
    const[filterStartDate, setfilterStartDate]=useState<string|null>(null)
    const[filterEndDate, setfilterEndDate]=useState<string|null>(null)
    const [selectedDates, setsselectedDates] = useState<string>("")

    //const [selectedStatus, setselectedStatus] = useState(100)
    //const [searchText, setSearchText] = useState("")




    function allStorage() {

        var values = [],
            keys = Object.keys(localStorage),
            i = keys.length;

        while ( i-- ) {
            values.push( localStorage.getItem(keys[i]) );
            console.log("storage: ",i, ": ", localStorage.getItem(keys[i]))
        }

        return values;
    }




    const { RangePicker } = DatePicker;



    const[_filter, setFilter] = useState<DealsFilter | null>(null)



    const setSelectedStatusF = (v : number)=> {
        localStorage.setItem("selectedStatus", v.toString());
        //setselectedStatus(v);
        setFilter(prev => ({
            ...prev,
            searchText: _filter? _filter?.searchText+"4" : "",
            
        }));


    }

    const setselectedDatesF =(v:any)=>{
        console.log("selectedDates v: ", v, " pageType=", pageType)
        if(v!=="" && v!==null){
            if(pageType && pageType==="sentInvoices"){
                localStorage.setItem("selectedSentDates", JSON.stringify(v))//Date.parse(v[0]).toString()+"-"+Date.parse(v[1]).toString());
            
            }
            if(pageType && pageType==="receivedInvoices"){
                localStorage.setItem("selectedReceivedDates", JSON.stringify(v))//Date.parse(v[0]).toString()+"-"+Date.parse(v[1]).toString());
            }
            setsselectedDates(Date.parse(v[0]).toString()+"-"+Date.parse(v[1]).toString())
            setFilter(prev => ({
                ...prev,
                searchText: _filter?.searchText,
                dateFrom:dayjs(Date.parse(v[0])).format("DD.MM.YYYY"),
                dateTo: dayjs(Date.parse(v[1])).format("DD.MM.YYYY"),
                selectedDates: Date.parse(v[0]).toString()+"-"+Date.parse(v[1]).toString()
            }));
            /*
            if(setFilterFunc){
                console.log(_filter)
                setFilterFunc(_filter)}
                
             */
        }
        if(v==="" || v===null){
            
            console.log("_filter: ", _filter)
            console.log("removing localstorage")
            localStorage.removeItem("selectedDates");
            localStorage.removeItem("selectedReceivedDates");
            localStorage.removeItem("selectedSentDates");
            if(setFilterFunc){setFilterFunc(
                {
                    "searchText": undefined,
                    "dateFrom": undefined,
                    "dateTo": undefined,
                    "selectedDates": undefined
                }
                
                
                
            )}
        }



    }


    const showhideFinished=(e :any)=>{
        if(e.target.checked ) {
            setFilter(prev => ({
                
                ...prev,
                searchText: _filter?.searchText+"2",
                hidefinished:true
            }));
        }
        else{
            setFilter(prev => ({
                ...prev,
                searchText: _filter?.searchText+"3",
                hidefinished:false
            }));
        }


    }


    useEffect(()=>{
        console.log("useEffect filter changed: ", _filter)
        if(setFilterFunc){setFilterFunc(_filter)}

        
       // allStorage();

    }, [_filter])


    useEffect(()=>{
        
        /*
        if(localStorage.getItem("selectedStatus")!==null) {
            const filter_status: number = Number(localStorage.getItem("selectedStatus"));
            if (filter_status !== 100) {
                setSelectedStatusF(filter_status);
                setshowFilterBadge(true);
            }
        }
        
         */
            if(_filter===null){
                console.log("List filter type: ", pageType)
                const dates_status: any= localStorage.getItem("selectedSentDates")

                console.log("List filter dates_status: ", dates_status)

                if(dates_status!=="" && dates_status!==null){
                    //console.log("dates_status: ", dates_status)
                    setselectedDatesF(JSON.parse(dates_status));
                    setshowDatesBadge(true);
                    console.log("setting filter dates: ", dates_status)


                }
            }
        
    },[])



    return (
        <div  style={{marginBottom: "20px", /*display: "inline-block", */ width: "100%", paddingRight: "10px",
        
        }}>
           
                <div  style={{ }}>
                    <div style={{margin: "0 auto",  width: (breakpoints.lg || breakpoints.xl) ?"auto" :"200px"}}>
                        <Space>
                            <div>
                                <Badge color={"#1c7884"} dot={showFilterBadge} style={{ display: "none"}}>
                                    <Select
                                        style={{display: "none"}}
                                        defaultValue={_filter?.requestStatus}
                                        value={_filter?.requestStatus}
                                        labelInValue={false}
                                        bordered={false}
                                        popupMatchSelectWidth={false}
                                        options={[
                                            { value: 100, label: t('Vsa') },
                                            { value: 0, label: t('Nova') },
                                            { value: 50, label: t('Zaključena') }
                                        ]}
                                        onChange={(v:number)=>{
                                            setshowFilterBadge(true);
                                            if(v===100){
                                                setshowFilterBadge(false);
                                            }
                                            setSelectedStatusF(v)
                                        }}
                                    />
                                </Badge>
                                <Badge color={"#1c7884"} dot={showDatesBadge}>
                                    <RangePicker variant="borderless"
                                                 style={{width: "220px",   borderBottom: "1px solid #999", borderRadius: "0"}}
                                                 placeholder={[t("Od_datum"),t("Do_datum")]}
                                                 format={'DD.MM.YYYY'}
                                                 defaultValue={[dayjs(_filter?.dateFrom,'DD.MM.YYYY'), dayjs(_filter?.dateTo,'DD.MM.YYYY')]}

                                                 value={[_filter?.dateFrom? dayjs(_filter?.dateFrom, 'DD.MM.YYYY') : null, _filter?.dateTo? dayjs(_filter?.dateTo, 'DD.MM.YYYY'):null]}

                                                 onChange={(v:any)=>{
                                                     console.log("v: ", v)
                                                     //console.log("selectedDates Date.parse: ", Date.parse(v[0]).toString()+"-"+Date.parse(v[1]).toString())
                                                     if(v){
                                                         setsselectedDates(Date.parse(v[0]).toString()+"-"+Date.parse(v[1]).toString())
                                                         setshowDatesBadge(true)
                                                     }else{
                                                         setsselectedDates("");
                                                         setFilter(prev => ({
                                                             ...prev,
                                                             dateFrom:"",
                                                             dateTo: "",
                                                             selectedDates: ""
                                                         }));


                                                         setshowDatesBadge(false)
                                                         setfilterStartDate(null);
                                                         setfilterEndDate(null);
                                                         //if(selectedStatus===100){
                                                         //    setshowFilterBadge(false)
                                                         //}
                                                     }
                                                     setselectedDatesF(v)

                                                 }} />
                                </Badge>

                                <Checkbox style={{marginLeft: "40px", display: "none"}}
                                          checked={_filter?.hidefinished}
                                          onClick={(e)=>{showhideFinished(e) }}>{t('Show_archived')}</Checkbox>


                            </div>

                            <Input.Search className={"emptyB"} style={{ display: "none"}}
                                          placeholder={t("Išči")}

                                          onSearch={(value)=>{
                                              setFilter(prev => ({
                                                  ...prev,
                                                  searchText: value
                                              }));
                                          }}
                                          onChange={(e)=>{
                                              setFilter(prev => ({
                                                  ...prev,
                                                  searchText: e.target.value
                                              }));

                                          }}

                                //suffix={
                                //    <Tooltip title="Extra information">
                                //        <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                //    </Tooltip>
                                //}
                            />

                            {/* 
         <Button onClick={GoToDetails}  className={"orangeButton"}>+ {t("Novo_naročilo")}</Button>
         <Button  className={"emptyButton"}
                  onClick={DowmloadExcel}

         >{t("Prenesite_v_Excel")}</Button>
*/}
                        </Space>
                    </div>
                    
                </div>
         
         

        </div>
    )
}

export default DocListFilter
         