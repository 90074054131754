import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {Avatar, Button, Card, Col, Divider, Form, Input, message, Row, Dropdown} from "antd";
import type {  MenuProps } from 'antd';

import type { SelectProps } from 'antd';
import { Select, Space } from 'antd';

import {UserOutlined, DownOutlined} from "@ant-design/icons";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import LangSelect from "../../../CommonComponents/LangSelect";



interface DataType {
    "id": string,
    "userName": string,
    "email": string,
    "phoneNumber": string,
    "firstNme": string,
    "lastName": string
}


const UserSettingsPage = () => {
    const {t} = useTranslation();

    const history= useHistory();
    const user = AuthService.getCurrentUser();
    const [userslang, setuserslang] = useState("en");
    const [langIcon, setlangIcon] = useState("🇬🇧");

  //  console.log("user: ", user);
    if(!user) {
        history.push("/login");
    }

    if(!AuthService.getCurrentOrganisation()) {
        history.push("/login");
    }
    

    const [form] = Form.useForm();
    const [Langform] = Form.useForm()

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };


    // const  [ownInfo, setownInfo] = useState<any>();
    const  [ownId, setownId] = useState<any>(AuthService.getCurrentOrganisation() ? AuthService.getCurrentOrganisation().id : "");

    const onFinish = (values: any) => {

        axios.put(AuthService.DATA_URL + 'user?email=' + user.user.email,
            {
                "email": values.email,
                "firstName": values.firstName,
                "lastName": values.lastName
            }, {headers: authHeader()})
            .then((res) => {
                    message.success('Podatki so bili uspešno shranjeni');
                }
            )
            .finally(() => {

            });
    }

    const [APIData, setAPIData] = useState<DataType>();

    useEffect(()=>{
        // setuserslang(user.language);
        if(!APIData)
            axios.get(AuthService.DATA_URL+'user/me', {headers: authHeader()})
                .then()
                .then((response) => {
                  //  console.log('user/me: ', response)
                    form.setFieldsValue({
                        firstName: response.data ? response.data.firstName : "rr",
                        lastName: response.data ? response.data.lastName : "",
                        email: response.data ? response.data.email : "",
                        login: response.data ? response.data.email : "",
                    });
                    Langform.setFieldsValue({
                        lang:response.data ? response.data.language : "en"
                    });

//                        console.log("Request response.data: ", response.data)
                    setAPIData(response.data);

                    switch(response.data.language) {
                        case "en": {
                            setuserslang("English");
                            setlangIcon("en")
                            break;
                        }
                        case "si": {
                            setuserslang("Slovenščina");
                            setlangIcon("si")
                            break;
                        }
                        case "fr": {
                            setuserslang("Français");
                            setlangIcon("fr")
                            break;
                        }
                        case "de": {
                            setuserslang("Deutsch");
                            setlangIcon("de")
                            break;
                        }
                        case "ru": {
                            setuserslang("Русский");
                            setlangIcon("ru")
                            break;
                        }
                        case "it": {
                            setuserslang("Italiano");
                            setlangIcon("it")
                            break;
                        }
                        case "uk": {
                            setuserslang("Українська");
                            setlangIcon("uk")
                            break;
                        }


                        default: {
                            setuserslang("English");
                            setlangIcon("en")
                            break;
                        }
                    }

                    //setuserslang(response.data.language);


//                        console.log("setownInfo: ", response.data)


                    
                   // console.log("ownId: ", ownId)
                   // setownId(response.data.id);
//                        setlogoId(response.data.logoId);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                    if(error.response.status===404){
                        console.log("notFound")
                    }

                })

    });



  

    const items: MenuProps['items'] = [
        { key: 'en', icon: <img src="/img/lang/en.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; English</span> },
        { key: 'si', icon: <img src="/img/lang/si.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Slovenščina</span> },
        { key: 'fr', icon: <img src="/img/lang/fr.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Français</span> },
        { key: 'de', icon: <img src="/img/lang/de.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Deutsch</span>},
        { key: 'ru', icon: <img src="/img/lang/ru.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Русский</span> },
        { key: 'it', icon: <img src="/img/lang/it.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Italiano</span> },
        { key: 'uk', icon: <img src="/img/lang/uk.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Українська</span> },
    ]

    const handleLanguageClick: MenuProps['onClick'] = (e) => {
       
        const tt: any = localStorage.getItem("userlanguage")===null ? "" : localStorage.getItem("userlanguage");
       // console.log("new language is set: ", e.key)
        localStorage.setItem("userlanguage", e.key);
        i18n.changeLanguage(e.key);
       // console.log("localStorage: ", localStorage.getItem("userlanguage"))



    };

    const menuProps = {
        items,
        onClick: handleLanguageClick,
    };


    return(
        <div>
            <Card style={{marginTop: "20px"}}>
                <Form
                    form={form}
                    name="wrap"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="horizontal"
                    labelCol={{ flex: '120px' }}
                    labelAlign="right"
                    labelWrap
                    wrapperCol={{ flex: 1 }}
                    colon={false}
                    //style={{ maxWidth: 600 }}
                >

                    <Row>
                        <Col span={8}>
                            <Form.Item
                                name="firstName"
                                label={t("Ime")}
                                rules={[{ required: true }, { type: 'string', warningOnly: true }]}
                            >
                                <Input placeholder="Ime" />
                            </Form.Item>
                            <Form.Item
                                name="lastName"
                                label={t("Priimek")}
                                rules={[{ required: true }, { type: 'string', warningOnly: true }]}
                            >
                                <Input placeholder={t("Priimek")} />
                            </Form.Item>

                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="email"
                                label={t("E_pošta")}
                            >
                                <Input placeholder={t("E_pošta")}  />
                            </Form.Item>
                            <Form.Item
                                name="login"
                                label={t("Login")}
                            >
                                <Input placeholder="Ime" bordered={false} disabled={true} />
                            </Form.Item>

                        </Col>
                        <Col span={4} offset={2}>
                            <LangSelect/>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                name="oldPassword"
                                label={t("Staro_geslo")}
                                rules={[ { type: 'string', warningOnly: true }]}
                            >
                                <Input placeholder={t("Staro_geslo")} />
                            </Form.Item>
                            <Form.Item
                                name="newPassword"
                                label={t("Novo_geslo")}
                                rules={[ { type: 'string', warningOnly: true }]}
                            >
                                <Input placeholder={t("Novo_geslo")} />
                            </Form.Item>
                            <Form.Item
                                name="newPasswordRepeat"
                                label={t("Novo_geslo")}
                                rules={[ { type: 'string', warningOnly: true }]}
                            >
                                <Input placeholder={t("Novo_geslo")} />
                            </Form.Item>
                            <Button className={"orangeButton"} htmlType="submit" style={{marginLeft:"120px"}}>
                                {t("Shraniti")}
                            </Button>


                        </Col>
                    </Row>


                </Form>
            </Card>










        </div>
    )};
export default UserSettingsPage;