import axios from "axios";
import {useHistory} from "react-router-dom";
//import {useState} from "react";
//import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import authHeader from "./auth-header";
import tokenHeader from "./token-header";
import {message} from "antd";
import axiosInstance from "../utils/axiosInstance";




export const DATA_URL = process.env.NODE_ENV === 'production'? "https://api.kvikbills.com/" : "https://api.dev.kvikbills.com/"  ;
export const NOTIFICATION_URL=process.env.NODE_ENV === 'production'? "https://api.fintim.online/notifications" : "http://localhost:7500/notifications";

/////fintim
////


export const refreshTokenRequest = async (token:any) =>
    (await axiosInstance.put(process.env.NEXT_PUBLIC_GATEWAY_URL + "/auth", {
        refreshToken: token
    })).data


export const login = (username: string, password: string) => {
    return axiosInstance
        .post(DATA_URL + "auth", {
            username,
            password,
        })
        .then((response) => {
            console.log("auth")
            if (response.data.token) {
                console.log("auth token")
                //usertoken
                localStorage.setItem("usertoken", response.data.token);
                localStorage.setItem("user", JSON.stringify(response.data));

                //ocalStorage.setItem("user", JSON.stringify(response.data));


                //loadUsersOrganisations()
                console.log("loading orgs..")
                return axiosInstance
                    .get(DATA_URL + "organization/", { headers: authHeader()
                    })
                    .then((org_response) => {
                        console.log("loadUsersOrganisations org_response: ", org_response)
                        localStorage.removeItem("organizations");

                        if(org_response.data instanceof Array){
                            localStorage.setItem("organizations", JSON.stringify(org_response.data));
                        }
                        else{
                            const orarr=[org_response.data]
                            localStorage.setItem("organizations", JSON.stringify(orarr));

                        }
                    })
               
                
                
                
            }

            return response.data;
        });
};

export const loadUsersOrganisations =() =>{
    console.log("loading orgs..")
    return axiosInstance
        .get(DATA_URL + "organization/", { headers: authHeader()
        })
        .then((org_response) => {
            console.log("loadUsersOrganisations org_response: ", org_response)
            localStorage.removeItem("organizations");
            //if(org_response.data instanceof Array){
               localStorage.setItem("organizations", JSON.stringify(org_response.data));
            //}
           // else{
           //     const orarr=[org_response.data]
           //     localStorage.setItem("organizations", JSON.stringify(orarr));
                
           // }
        })
}



export const logout = () => {
    
    localStorage.removeItem("user");
    localStorage.removeItem("organizations");
    localStorage.removeItem("current_organization");
    //localStorage.removeItem("userlanguage");
    const user = getCurrentUser();
    //console.log("authservice.tsx user: ", user);
    
};

export const getCurrentUser = () => {
    const userStr = localStorage.getItem("user");
    //console.log("getCurrentUser userStr: ", userStr)
    if (userStr) return JSON.parse(userStr);
    return null;
};

export const getUsersOrganisations = () => {
    const userStr = localStorage.getItem("organizations");

    if (typeof userStr== "undefined" ) {
        console.log("organizations is undefined")
        return null;
    }
    else{
        if(userStr) {
            if(userStr=="undefined"){
            }
            else{
                console.log("JSON.parse(userStr): ", JSON.parse(userStr))
                return JSON.parse(userStr);
            }
        }
    }
    return null;
};






export const getCurrentOrganisation = () => {
    
    const userStr = localStorage.getItem("current_organization");

        //console.log("typeof userStr: ", typeof userStr)
        if (typeof userStr== "undefined" ) {
            return null;
        }
        else{
            if(userStr) {
                if(userStr=="undefined"){
                    console.log("userStr.length: ", userStr.length, userStr)
                    
                }
                else{
                    //console.log("get current org: ", JSON.parse(userStr))
                    let uorg=JSON.parse(userStr);
                    if(!uorg.bankAccounts){
                        uorg.bankAccounts=[]
                    }
                    return uorg;//JSON.parse(userStr);
                }

            } 
        }
    

    return null;
};

export const refreshUser = () => {
    return axios
        .get(DATA_URL + "Authenticate/refresh-my-user", {
            headers: authHeader()
        },)
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
};
