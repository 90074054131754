import React, {useRef, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import Product from "../../../types/Product";

import ProductSelect from "./ProductSelect";

import {Form, Input, Row, Col, Select, InputNumber, Spin, Grid, ConfigProvider, Divider} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {Simulate} from "react-dom/test-utils";
import submit = Simulate.submit;
import {vatRateToNumber} from "../../../utils/helpers";
import {useTranslation} from "react-i18next";
interface DocPositionProps {
    keyStr?: number;
    integrationSpecificId? : string;
    name? : string;
    quantity? : number;
    price? : number;
    vatRate? : string;
    forceReload?: boolean;
    isLoaded?: () => void;
    deleteFunc?: (key: number) => void;
    addFunc?: (values: any) => void;
    isDisabled? : boolean;

}

const DocPositionForm = ({keyStr,integrationSpecificId, forceReload, name, quantity, price, vatRate, isLoaded, deleteFunc, addFunc, isDisabled}: DocPositionProps) => {

    /*
    console.log("Position Form - incoming keyStr: ", keyStr)
    console.log("Position Form - incoming keyStr: ", integrationSpecificId)
    
     */
    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }

    const {t} = useTranslation();

    const { useBreakpoint } = Grid;
    const breakpoints = useBreakpoint();

    const [formPos] = Form.useForm();

    const [_productId, set_productId] = useState("0");
    const [_naziv, set_naziv] = useState();
    const [_kolicina, set_kolicina] = useState();
    const [_cena, set_cena] = useState();
    const [_davek, set_davek] = useState();




    useEffect(()=>{
        formPos.setFieldsValue(
            {
                ["naziv"] : name ? name : _naziv? _naziv : "",
                ["kolicina"] : quantity ? quantity : _kolicina? _kolicina : 0,
                ["davek"] : vatRate ? vatRate : _davek? _davek : "standard",
                ["cena"] : price ? price : _cena? _cena : 0
            }
        );
    },[name, quantity, vatRate, price])


    const deletePos=(key:number)=>{
        if(deleteFunc){
            deleteFunc(key);
        }
    }
    //console.log(keyStr)

    const SavePosition = (prodId: any)=>{
        
        console.log("SavePosition received value:", prodId)
        console.log(_productId)

        console.log("PositionForm _productId to send: ", _productId)
        console.log("PositionForm prodId to send: ", prodId)
        console.log("PositionForm keyStr to send: ", keyStr)


 

       

        const sendValues = {
            id : keyStr,
            name : formPos.getFieldValue(["naziv"]),
            price: formPos.getFieldValue(["cena"]),
            vatRate: formPos.getFieldValue(["davek"]),
            quantity: formPos.getFieldValue(["kolicina"]),
            integrationSpecificId: prodId,
        }

        console.log("PositionForm values to send: ", sendValues)
        set_naziv(formPos.getFieldValue(["naziv"]));
        set_kolicina(formPos.getFieldValue(["kolicina"]))
        set_cena(formPos.getFieldValue(["cena"]));
        set_davek(formPos.getFieldValue(["davek"]))

        if(addFunc){
            addFunc(sendValues);
        }
    }

    if(!keyStr)return(
        <Spin> </Spin>
    )

    const setNewProduct=(prod?: Product)=>{
        console.log("Product received in PositionForm", prod)
        set_productId(prod?.integrationSpecificId ? prod.integrationSpecificId : "111")
        console.log(prod?.integrationSpecificId ? prod.integrationSpecificId : "111")
        formPos.setFieldsValue(
            {
                ["naziv"] : prod?.name,
                ["kolicina"] : 1,
                ["davek"] : prod?.vatRate,
                ["cena"] : prod?.price
            }
        );
        SavePosition(prod?.integrationSpecificId);
    }

    return(
        <div style={{width: "100%"}}>


            <ConfigProvider
                theme={{
                    components: {
                        Form: {
                            itemMarginBottom : (breakpoints.lg || breakpoints.xl)  ? 24 : 8
                        },
                    },
                }}
            >
            

            <Form
                form={formPos}
                name={keyStr.toString()}
                layout="vertical"
                //labelCol={{ flex: '100px' }}
                labelAlign="right"
                labelWrap
                //wrapperCol={{ flex: 1 }}
                //onFinish={SavePosition}
                onChange={()=>SavePosition(_productId)}
                disabled={isDisabled}
                initialValues={
                    {
                        ["vrsta"]:0
                    }
                }
            >

                <Row  gutter={8}>
                    <Col
                        xs={24} sm={23} md={11} lg={11} xl={11}

                       // span={11}
                    >
                        <Form.Item name="naziv" label={(breakpoints.lg || breakpoints.xl)  ? "" : t("Naziv_storitva_blaga")}>
                            <ProductSelect afterCreate={setNewProduct} defaultSelected={integrationSpecificId? integrationSpecificId : "0"}  />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={8} sm={8} md={8} lg={3} xl={3}
                        span={3}
                    >
                        <Form.Item name="kolicina" label={(breakpoints.lg || breakpoints.xl)  ? "" : t("Količina")}>
                            <InputNumber 
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={6} sm={6} md={6} lg={3} xl={3}
                        span={3}>
                        <Form.Item name="davek" label={(breakpoints.lg || breakpoints.xl)  ? "" : t("Davek")}>
                            <Select
                                options={
                                    [
                                        { label: "22%", value: "standard" },
                                        { label: "9.5%", value: "reduced" },
                                        { label: "8%", value: "flat" },
                                        { label: "5%", value: "lower" },
                                        { label: "Exempted 0%", value: "exempted" },
                                        { label: "Non-taxable 0%", value: "non-taxable" },
                                        
                                    ]}
                                onChange={()=>SavePosition(_productId)}
                                //onChange={(e) => ShowContractorDoc(e)}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={8} sm={8} md={8} lg={3} xl={3}

                        span={4}
                    >
                        <Form.Item name="cena"  label={(breakpoints.lg || breakpoints.xl)  ? "" : t("Cena")}>
                            <InputNumber
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={1} sm={1} md={1} lg={1} xl={1}
                        offset={(breakpoints.lg || breakpoints.xl)  ? 1 : 0}
                        //span={1} 
                        style={{paddingTop: (breakpoints.lg || breakpoints.xl)  ? "4px" : "34px"}}
                    >

                        {isDisabled ?
                        ""
                        :
                            <a onClick={()=>deletePos(keyStr ? keyStr : 0)} ><DeleteOutlined /></a>
                        }
                        

                    </Col>
                    {
                        (breakpoints.lg || breakpoints.xl)  ? "" : <Divider />
                        
                    }

                </Row>
                {_naziv ? "" :
                    "" //<Button htmlType="submit">Shraniti</Button>
                }
            </Form>
            </ConfigProvider>
        </div>
    )}
export default DocPositionForm;