import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Table} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import StockMovement from '../../../types/StockMovement';
import {waregouseService} from "../../../Services/warehouseService";
import * as AuthService from "../../../Services/auth-service";
import {useHistory, useParams} from "react-router-dom";
import dayjs from "dayjs";
import Product from "../../../types/Product";
import {RightOutlined} from "@ant-design/icons";

interface ItemTableProps {
    startCount? : number;
    movements: StockMovement[];
    endCount? : number;
    item? : Product
    
  }
  


const ItemTable = () => {
    const {t} = useTranslation();

    const history= useHistory();


    const [loading, setLoading] = useState(false);
    const [movs, setMovs] = useState<StockMovement[]>([]);

    
    const [dates, setDates] = useState<[string, string] | undefined>(undefined);
    const { id } = useParams<{ id?: string }>(); // Получение ID поставщика из URL, если он есть



    const [initialStock, setInitialStock] = useState<number | undefined>();
    const [finalStock, setFinalStock] = useState<number | undefined>();
    
    const [itemName, setItemName] = useState<string|null|undefined>();
    
//https://api.dev.kvikbills.com/warehouse/66ec6eb8dc36da32437b6ebd
// /9072648/2015-02-20T13:59:31.238Z/2025-02-20T13:59:31.238Z




    const MovementTypeSign=(m: StockMovement)=>{
        if(m.movementType==="sale" || m.movementType==="rental_out" || m.movementType==="write_off"){
            return(0-(m.quantity? m.quantity : 0))
        }
        else{
            return(0+(m.quantity? m.quantity : 0))

        }
    }

    
    
    useEffect(() => {
        const fetchMovements = async () => {
            setLoading(true);
            try {

                var date = new Date();
                
               // @ts-ignore
                console.log(dates)

                //console.log(dates? (dayjs(dates[0], "DD.MM.YYYY")).toISOString(): "2015-02-20T00:00:00.000Z")
                // @ts-ignore
                //console.log(dates? (dayjs(dates[1], "DD.MM.YYYY")).toISOString(): "2025-02-20T00:00:00.000Z")
                
                const response = await waregouseService.getForProduct(
                    AuthService.getCurrentOrganisation().id,
                    id,
                    dates? ( dayjs( dates[0], "DD.MM.YYYY")).toISOString() : "2000-02-20T13:59:31.238Z", //["2015-02-20T13:59:31.238Z", "2025-02-20T13:59:31.238Z"]
                    dates? ( dayjs( dates[1], "DD.MM.YYYY")).toISOString() : "2050-02-20T13:59:31.238Z", //["2015-02-20T13:59:31.238Z", "2025-02-20T13:59:31.238Z"]
//                    dates? ( dates[0]? dayjs( dates[0], "DD.MM.YYYY") : dayjs("2005-02-20T00:00:00.000Z")).toISOString() : "2015-02-20T13:59:31.238Z", //["2015-02-20T13:59:31.238Z", "2025-02-20T13:59:31.238Z"]
//                    dates? ( dates[1]? dayjs( dates[1], "DD.MM.YYYY") : dayjs("2025-02-20T00:00:00.000Z")).toISOString() : "2015-02-20T13:59:31.238Z", //["2015-02-20T13:59:31.238Z", "2025-02-20T13:59:31.238Z"]
                
                );

                setItemName(response.item?.name)
                setInitialStock(response.startCount);
                setFinalStock(response.endCount)
                var varstock = response.startCount ? response.startCount : 0;
                setMovs(response.movements
                    .sort((a: StockMovement, b: StockMovement) => {
                    return +new Date(a.movementDate? a.movementDate : "") - +new Date(b.movementDate ? b.movementDate : "")
                    }).map((m, i)=> {

                            varstock = varstock+MovementTypeSign(m);
                            console.log(varstock);
                            return {
                                _id: m._id,// string;// "66ec6ed9dc36da32437b6ecb",
                                name: m.name,//string;//: "test stock movement",
                                movementType: m.movementType,// string;//: "sale",
                                movementDate: m.movementDate,// string;//: "2024-07-07T00:00:00.000Z",
                                initialQuantity: m.initialQuantity ? m.initialQuantity : varstock-MovementTypeSign(m),//? : number;
                                quantity: m.quantity,// string;//: 11,
                                finalQuantity: m.finalQuantity ? m.finalQuantity : varstock,// number;
                                price: m.price,// string;//: 5,
                                clientName: m.clientName,//string;//: "client",
                                organizationId: m.organizationId,// string;//: "66ec6eb8dc36da32437b6ebd",
                                integrationInvoiceItemId: m.integrationInvoiceItemId,// string;//: "9072648",
                                createdAt: m.createdAt,//string;//: "2024-09-19T18:35:05.875Z",
                                updatedAt: m.updatedAt,//string;//: "2024-09-19T18:35:05.875Z",
                                __v: m.__v,//string;//: 0

                            }
                        }
                    )
                
                
                
                
                );
                console.log("response:", response)
            } catch (error) {
                console.error('Ошибка при получении задач:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchMovements();
    }, [dates]);
    
    
    
    const columns: ColumnsType<StockMovement> = [
        {
          title: t('Movement_date'),
          dataIndex: 'date',
          key: 'date',
            render: (text, record) => {
                return <>
                    {(new Date(record.movementDate? record.movementDate : "")).toLocaleDateString("ru-RU")}
                    <br/><span style={{fontSize: "12px"}}>{record.name}</span>

                </>;
            },
        },
        {
          title: t('Initial_Stock'),
          dataIndex: 'stock',
          key: 'stock',
            render: (text, record) => {
                return <>{record.initialQuantity}</>;
            },
        },
        {
          title: t('Prejemki'),
          dataIndex: 'incoming',
          key: 'incoming',
            render: (text, record) => {
                return <>{record.movementType==="production" || record.movementType==="purchase" || record.movementType==="rental_return" ?  
                    <>&#x2b; {record.quantity}
                        <br/><span style={{color: "#999", fontSize: "12px"}}>{t(record.movementType)}</span>
                </> : ""}</>;
            },
        },
        {
          title: t('Odtujitve'),
          dataIndex: 'outgoing',
          key: 'outgoing',
            render: (text, record) => {
                return <>{record.movementType==="sale" || record.movementType==="rental_out" || record.movementType==="write_off" ?  <>&#x2212; {record.quantity}
                    <br/><span style={{color: "#999", fontSize: "12px"}}>{t(record.movementType)}</span>
                </> : ""}</>;
            },
        },
        {
          title: t('Final_Stock'),
          dataIndex: 'finalStock',
          key: 'finalStock',
            render: (text, record) => {
                return <>{record.finalQuantity}</>;
            },
        },
      ];


    const { RangePicker } = DatePicker;



    return<>
        
        <h1>
            
            <span style={{color: "#999", cursor: "pointer", borderBottom: "1px dashed #999"}} onClick={()=> history.push("/warehouse")}>
               {t("Warehouse")}
            </span>            
            
            
            <span style={{color: "#999"}}>
                    &nbsp;<RightOutlined size={16} style={{fontSize:"18px"}} /> &nbsp;
                </span>
            {itemName}</h1>
        <RangePicker
            format={"DD.MM.YYYY"}
        placeholder={[t("Od_datum"), t("Do_datum")]}
        onChange={(dates, dateStrings) => {
        if (dateStrings[0] && dateStrings[1]) {
            setDates([dateStrings[0], dateStrings[1]]);
        } else {
            setDates(undefined);
        }
    }}
        />


        <Button
            onClick={()=>{history.push("/createmovement/"+id)}}
            style={{float: "right"}}
            type={"primary"} >+ {t("New_movement")}</Button>


        <h3>{t("Initial_Stock")}: {initialStock}</h3>

        <Table columns={columns} 
               pagination={false}
               scroll={{ y: 'calc(57vh)' }}
               loading={loading}
               size={"small"}
               rowClassName={"rowpointer"}
               
               onRow={(record, rowIndex) => {
                   return {
                       onClick: (event) => {
                           history.push("/editmovement/" + record._id);
                       },
                   };
               }}


               dataSource={movs} />

        <h3>{t("Final_Stock")}:{finalStock}</h3>

    </>
};

export default ItemTable;