import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {useTranslation} from "react-i18next";
import invoice from "../../../types/invoice";
import axios from "axios";
import {DATA_URL} from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import CommonListFilter from "../../../types/CommonListFilter";
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import {
    Avatar,
    Badge,
    Button,
    DatePicker,
    Grid,
    List,
    Modal,
    Popover,
    Skeleton,
    Space,
    Table,
    TableProps,
    Tooltip
} from "antd";
import {AccountingStatus, dateForServer, InvoiceStatusColor, ServerdateToPoints, ToMoney} from "../../../utils/helpers";
import ShowDoc from "./ShowDoc";
import {ColumnsType} from "antd/es/table";
import {subscribe, unsubscribe} from "../../../CommonComponents/Notifications/NotificationContext";
import DocListFilter from "./DocListFilter";
import dayjs from "dayjs";
import axiosInstance from "../../../utils/axiosInstance";


interface DocListProps{
    setHeight? : number
    shortNames? : boolean | null;
}


const mapEnumToString=(i : number)=>{
    switch ( i ) {
        case 0:
            return "Drugačen dokument"
        case 10:
            return "Račun"
        default:
            return "Drugačen dokument"
    }
}

const SentDocsTable = ({ setHeight, shortNames} : DocListProps) => {
    const history= useHistory();
    const user = AuthService.getCurrentUser();


    const { RangePicker } = DatePicker;


    const { useBreakpoint } = Grid;
    const breakpoints = useBreakpoint();



    if(!user){
        history.push("/login");
    }
    const {t} = useTranslation();
    


    const [userOrganisation, setuserOrganisation] = useState(AuthService.getCurrentOrganisation()? AuthService.getCurrentOrganisation().id: "");
    //console.log(userOrganisation.id)
    const [docs, setdocs] = useState<invoice[]|null>(null)
    
    
    


    const[pageloading, setpageloading] = useState<boolean>(false)
    
    const[pageSize, setPageSize] = useState(10)
    const[totalResults, setTotalResults] = useState(10)
    const[currentPage, setCurrentPage] = useState(1)


    
    const v:any = localStorage.getItem("selectedSentDates");
    console.log("localStorage dates: ", JSON.parse(v)) 
    
    const [filter, setFilter]=useState<CommonListFilter | null>({
        dateFrom: v? dayjs(Date.parse(JSON.parse(v)[0])).format("DD.MM.YYYY") : "",
        dateTo: v? dayjs(Date.parse(JSON.parse(v)[1])).format("DD.MM.YYYY"): "",
    })
    
     


    



    const fetchSentInvoices = async (id: string, currentPage: number|undefined, pageSize: number|undefined): Promise<invoice[]> => {
       
//        console.log("doctable _filter in fetchSentInvoices: ", _filter)
//        console.log("doctable filter in fetchSentInvoices: ", filter)

        try {
            
            const getUrl='https://api.dev.kvikbills.com/integration/invoices/sent/'+id
                +"?page="+(currentPage? currentPage : 0)
                +(pageSize? "&pageSize="+pageSize : "")
                +( filter?.dateFrom? "&fromDate="+(dateForServer(filter.dateFrom)) : "" )
                +( filter?.dateTo? "&toDate="+(dateForServer(filter.dateTo)) : "" );
            
            console.log("getIrl: ", getUrl)
            
            
                const response = await axiosInstance
                    .get('https://api.dev.kvikbills.com/integration/invoices/sent/'+id
                        +"?page="+(currentPage? currentPage : 1)
                        +(pageSize? "&pageSize="+pageSize : "")
                        +( filter?.dateFrom? "&fromDate="+(dateForServer(filter.dateFrom)) : "" )
                        +( filter?.dateTo? "&toDate="+(dateForServer(filter.dateTo)) : "" )
                        ,{headers: authHeader()})
            console.log("response:", response)
            
                setTotalResults(response.data.pagination.totalResults);
                //console.log("response.data.data: ", response.data.data)
                //console.log("got with filter: ", filter)
                return response.data.data.map((invoice: invoice) => ({ ...invoice, sentOrReceived: 'sent' }));
            
        }
        catch(e: any) { console.log("exception: ", e? e?.response.data : ""); return []}
       
    };


    const fetchReceivedInvoices = async (id: string, _filter : CommonListFilter, currentPage: number|undefined, pageSize: number|undefined): Promise<invoice[]> => {
        try{
            const response = await axiosInstance.get('https://api.dev.kvikbills.com/integration/invoices/received/'+id
                +"?page="+(currentPage? currentPage : 1)
                +(pageSize? "&pageSize="+pageSize : "")
                +( _filter.dateFrom? "&fromDate="+(_filter.dateFrom) : "" )
                +( _filter.dateTo? "&toDate="+(_filter.dateTo) : "" )
                , {headers: authHeader()}
            );
            setTotalResults(response.data.pagination.totalResults);
            return response.data.data.map((invoice: invoice) => ({ ...invoice, sentOrReceived: 'received', key: invoice.integrationSpecificId }));
        }
        catch { console.log("exception"); return []}
    };
    


    const fetchAllInvoices = async (id: string,  currentPage: number, pageSize: number) => {
        console.log("fetchAllInvoices filter: ", filter)
        setpageloading(true)
        try {
            const [sentInvoices] = await Promise.all([fetchSentInvoices(id, currentPage, pageSize)]);
            
            console.log("filter: ", [sentInvoices])
            
            setdocs([...sentInvoices]);

/*
            if(invoiceType==="sent"){
            }
            if(invoiceType==="received"){
                const [receivedInvoices] = await Promise.all([fetchReceivedInvoices(id,filter, currentPage, pageSize)]);
                setdocs([...receivedInvoices]);
            }
            if(invoiceType==="all"){
                const [sentInvoices, receivedInvoices] = await Promise.all([fetchSentInvoices(id,filter, currentPage, pageSize), fetchReceivedInvoices(id,filter, currentPage, pageSize)]);
                setdocs([...sentInvoices, ...receivedInvoices]);
            }
            
 */
            setpageloading(false)
            console.log("sentInvoices: ", [sentInvoices])
        } catch (error) {
            console.error('Ошибка при получении данных:', error);
            setpageloading(false)
        }
    };

    const changeOrg=(id:string)=>{
        setuserOrganisation(id)
        fetchAllInvoices(id,  0, pageSize)
    }

    
    
    const applyFilter=(filter: any)=>{
        console.log("Filter :", filter)
        if(filter!==null){
            setFilter(filter)
            fetchAllInvoices(userOrganisation, 0, pageSize);
        }

    }

    
  
    useEffect(()=>{
       console.log("useEffect in DocsTab has filter: ", filter)
       
            subscribe("OrganizationChange", (data: any) => changeOrg(data.detail))
        
        
            fetchAllInvoices(userOrganisation, currentPage, pageSize);

            return () => {
                unsubscribe("OrganizationChange", (data: any) => changeOrg(data.detail));
            }

    },[ filter, currentPage, pageSize])

    
    

    const columns: ColumnsType<invoice> = [
        {
            key: '0',
            title: "",
            dataIndex: 'integrationSpecificId',
            width: 40,
            render: (text, record)  => {
                return (                
                    <div style={{color: record.status?.toLowerCase().includes("draft") ? "green" : "#000"}}>
                    <Tooltip title={record.sentOrReceived === "sent" ? t("Sent_invoice") : t("Received_invoice")}>
                        {record.sentOrReceived === "sent" ? <UploadOutlined style={{fontSize: "16px"}}/> :
                            <DownloadOutlined style={{fontSize: "16px"}}/>}
                    </Tooltip>
                </div>
)            }
            
        },
        {
            key: '1',
            title: t('Številka'),
            dataIndex: 'integrationSpecificId',
            width: 250,
            onFilter: (value, record) => {
                return (
                    String(record.customer? record.customer.name : "" ).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.documentReference? record.documentReference : "").toLowerCase().includes(value.toString().toLowerCase())
                )
            },
            render : (text, record)  =>
                <div>
                    <span style={{color: record.status?.toLowerCase().includes("draft")? "green": "#000"}}>
                        <Link style={{color: record.status?.toLowerCase().includes("draft") ? "green": "#000"}}  
                              to={record.sentOrReceived==="sent"? `/docs/${record.integrationSpecificId}` : `/docs/received/${record.integrationSpecificId}`}> 
                            <span>
                                {mapEnumToString(10)+" "+record.documentReference}
                            </span>
                        </Link><br/>
                        <span style={{fontSize: "13px", color: "#999" }}>{t("from")+" "+ServerdateToPoints(record.dateIssued)}</span>
                    </span>
                </div>
        },
        {
            title: t('Nasprotna_stranka'),
            dataIndex: 'integrationSpecificId',
            key: 'integrationSpecificId',
            //width: 250,
            render : (text, record)  => 
                <span>
                    {record.customer?  record.customer.name : "-"}
                </span>
        },
        {
            title: t('Amount'),
            dataIndex: 'amount',
            key: 'amount',
            width: 150,
            align: "right",
            render : (text, record)  =>
                <span style={{color: InvoiceStatusColor(record.paymentStatus? record.paymentStatus : "")}}>
                    {   
                        ToMoney(record.amount? record.amount : 0)
                    }

                    <br/>
                        <span style={{color: InvoiceStatusColor(record.paymentStatus? record.paymentStatus : "")}}>
                            {
                                record.dateTransaction?
                                    <span style={{fontSize: "12px" }}>    
                                        {ServerdateToPoints(record.dateTransaction)}&nbsp;
                                    </span>
                                    :""
                            }
                            <span>
                            {ToMoney(record.paidValue ? record.paidValue : 0)}
                            </span>
                        </span>
                
                
                </span>
        },
        {
            title: ' ',
            key: 'paymentStatus',
            fixed: 'right',
            width: 200,
            render: (text, record) =>
                <div>
                    <span style={{color: InvoiceStatusColor(record.paymentStatus? record.paymentStatus : "")}}>
                    {t(record.paymentStatus? record.paymentStatus?.replaceAll(" ","_") : "")}
                    </span>
                </div>
        },
        {
            title: " ",//t('Status'),
            dataIndex: 'status',
            key: 'status',
            //width: 250,
            render : (text, record)  =>
                <span> <i>
                    {AccountingStatus(record.status)==="draft" ?
                        <>{t("On_processing")}<br/>
                            <span style={{color: "#999", fontSize: "12px"}}>{t("May_still_make_changes")}</span>
                        </>
                        :
                        <>{t("Invoice_is_booked")}<br/>
                            <span style={{color: "#999", fontSize: "12px"}}>{t("No_changes_aalowed")}</span>
                        </>
                    }
                </i></span>
        },

    ];



    const handleTableChange: TableProps['onChange'] = (pagination) => {
        
        console.log("pagination:", pagination)
        setCurrentPage(pagination.current? pagination.current : 1)

    };







    return <>



      <DocListFilter
          pageType={"sentInvoices"} setFilterFunc={(filter)=>{applyFilter(filter)}}  />



        {(breakpoints.lg || breakpoints.xl) ?


            <Table
                locale={{
                    triggerDesc: 'Нажмите, чтобы отсортировать по убыванию',
                    triggerAsc: 'Нажмите, чтобы отсортировать по возрастанию',
                    cancelSort: 'Отменить сортировку'
                }}
                columns={columns}
                dataSource={docs? docs: []}
                loading={pageloading}
                scroll={{ y: 'calc(53vh)' }}
                pagination={{ pageSize: pageSize, current: currentPage, total: totalResults }} size="small"
                onChange={handleTableChange}

            />
            
            :
            <List
                dataSource={docs? docs: []}
                loading={pageloading}
                renderItem={(item) => (
                    <List.Item
                       // actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">more</a>]}
                    >
                        <List.Item.Meta
                            title={<span>{item.customer?  item.customer.name : "-"}</span>} 
                            description={
                                <div>
                                    <span style={{color: item.status?.toLowerCase().includes("draft")? "green": "#000"}}>
                                        <Link style={{color: item.status?.toLowerCase().includes("draft") ? "green": "#000"}}
                                              to={item.sentOrReceived==="sent"? `/docs/${item.integrationSpecificId}` : `/docs/received/${item.integrationSpecificId}`}> 
                                            <span>
                                                {mapEnumToString(10)+" "+item.documentReference}
                                            </span>
                                        </Link><br/>
                                        <span style={{fontSize: "13px", color: "#999" }}>
                                            {t("from")+" "+ServerdateToPoints(item.dateIssued)}
                                        </span>
                                    </span>
                                </div>
                            }
                        />
                        <div style={{textAlign:"right"}}>
                            {
                                <span style={{color: InvoiceStatusColor(item.paymentStatus? item.paymentStatus : "")}}>
                                    {ToMoney(item.amount? item.amount : 0)}
                                    <br/>
                                    <span style={{color: InvoiceStatusColor(item.paymentStatus? item.paymentStatus : "")}}>
                                        {
                                            item.dateTransaction?
                                                <span style={{fontSize: "10px" }}>    
                                                    {ServerdateToPoints(item.dateTransaction)}&nbsp;
                                                </span>
                                                :""
                                        }
                                        <span>
                                        {ToMoney(item.paidValue ? item.paidValue : 0)}
                                        </span>
                                    </span>
                                </span>                                }
                        </div>
                    </List.Item>
                )}
            />
        }





      


    </>

}

export default SentDocsTable