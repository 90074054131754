// src/services/productGroupService.ts
import axios from 'axios';
//import { ProductGroup } from '../types/ProductGroup';

import {DATA_URL} from './auth-service'
import authHeader from "./auth-header";
import InvoiceItem from "../types/invoiceItem";
import StockMovement from "../types/StockMovement";
import Product from "../types/Product";

const API_URL = DATA_URL+'warehouse';

export const waregouseService = {
    // Получение всех групп продуктов
    async getAll(orgId? : string): Promise<InvoiceItem[]> {
        const response = await axios.get<InvoiceItem[]>(API_URL
            //+"/"+orgId
            , {headers: authHeader()});
        return response.data;
    },


    async getWithAmount(orgId?: string ): Promise<any[]> {
        const response = await axios.get<InvoiceItem[]>(API_URL
            +"/"+orgId+"?detailed=-1"
            , {headers: authHeader()});
        return response.data;
    },

    async getForProduct(orgId?: string, invoiceItemId? : string, afterDate? : string, beforeDate? : string  ): Promise<{
        startCount? : number;
        movements: StockMovement[];
        endCount? : number;
        item? : Product


    }> {
        const response = await axios.get<{
            startCount? : number;
            movements: StockMovement[];
            endCount? : number;
            item? : Product


        }>(API_URL
            +"/"+orgId+"/"+invoiceItemId+"/"+afterDate+"/"+beforeDate
            , { headers: authHeader()});
        return response.data;
    },
    
    
    
    
    // Получение перемещения по ID
    async getById(orgId?: string, id?: string): Promise<StockMovement> {
        const response = await axios.get<StockMovement>(`${API_URL}/id/${id}`, {headers: authHeader()});
        return response.data;
    },

    // Создание новой группы продуктов
    async create(productGroup: any): Promise<InvoiceItem> {
        const response = await axios.post<any>(API_URL, productGroup, {headers: authHeader()});
        return response.data;
    },

    // Обновление существующей группы продуктов
    async update(id: string, productGroup: any): Promise<void> {
        await axios.put(`${API_URL}/${id}`, productGroup, {headers: authHeader()});
    },

    // Удаление группы продуктов
    async delete(id: string): Promise<void> {
        await axios.delete(`${API_URL}/${id}`, {headers: authHeader()});
    }
};
