import {Link, useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import Product from "../../../types/Product";
import {Button, Divider, Drawer, Grid, List, Spin, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import bankAccount from "../../../types/BankAccount";
import EditContractorComp from "../../Counterparts/Components/EditComponent";
import BankAccountDetailPage from "./BankAccountDetails";
import {DeleteOutlined} from "@ant-design/icons";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import * as BankAccountService from "../../../Services/BsnkAccountService";




interface Params {
   
    accounts? :bankAccount[] | null; 
    aftercreate? : (data: any) => void;
}


const BankAccountTable = ({ accounts, aftercreate}: Params) => {
    const history = useHistory();
    const user = AuthService.getCurrentUser();


    if (!user) {
        history.push("/login");
    }
    const {t} = useTranslation();

    const { useBreakpoint } = Grid;
    const breakpoints = useBreakpoint();


    const [userOrganisation, setuserOrganisation] = useState(AuthService.getCurrentOrganisation()? AuthService.getCurrentOrganisation() : null);


    const [bankAccounts, setBankAccounts] = useState<bankAccount[] | null>(AuthService.getCurrentOrganisation().bankAccounts ? AuthService.getCurrentOrganisation().bankAccounts.map((acc: bankAccount, idx: number)=>({
        position_number: idx,//acc.iban,
        iban: acc.iban,
        bankName: acc.bankName,
        bic:acc.bic
        
    })): [])


    
    
    const [clickedAccount, setClickedAccount] = useState<bankAccount | null>(null)
    const [pageloading, setpageloading] = useState<boolean>(false)


    

    

    const columns: ColumnsType<bankAccount> = [
        {
            key: 0,
            title: t('Naziv'),
            dataIndex: 'bankName',
            render: (text, record) => {
                return (
                    <>
                        {text}
                    </>

                )}
        },
        {
            key: 1,
            title: "SWIFT",
            dataIndex: 'bic',
            render: (text, record) => {
                return (
                    <div>
                        {text}
                    </div>
                )}
        },
        {
            key: 2,
            title: "IBAN",
            dataIndex: 'iban',
            render: (text, record) => {
                return (
                    <div>
                        {text}
                    </div>
                )}
        },
    ]


    const [openContractor, setopenContractor] = useState(false);


    const setNewAccount = (value : any):string => {
        setpageloading(true)
        setopenContractor(false)
        console.log(value)
        
        const accs=bankAccounts;
       
        if(value.position_number==="new"){
            
            setBankAccounts(prevAccounts => {
                if (prevAccounts === null) {
                    return [{
                        position_number: "1",
                        name: value.bankName,
                        iban: value.iban,
                        bic: value.bic
                    }];
                } else {
                    return [...prevAccounts, {
                        position_number: (bankAccounts?.length? bankAccounts?.length+1: "1").toString(),
                        name: value.bankName,
                        iban: value.iban,
                        bic: value.bic
                    }];
                }
            })
        }

        if(value.position_number!=="cancel"){
            // @ts-ignore
            const index = bankAccounts.indexOf(bankAccounts.find(p=>p.position_number===value.position_number), 0);
            if (bankAccounts) {
                if(bankAccounts[index]){
                    bankAccounts[index].bankName = value.name;
                    bankAccounts[index].iban=value.iban;
                    bankAccounts[index].bic=value.bic;
                }
            } 
        }

        if(value.position_number=="cancel"){
            setBankAccounts(bankAccounts)
        }
        setpageloading(false)
        return  "ok"
    }



    const handleDelete=(posNumber? : string | null | undefined)=> {
        console.log("handleDelete in baTable: ", posNumber)
        
        
        console.log("bankAccounts: ", bankAccounts);
        
        // @ts-ignore
        const index = bankAccounts?.indexOf(bankAccounts?.find(p=>p.position_number===posNumber), 0);
        console.log("index: ", index)
        // @ts-ignore
        if (index > -1) {
            // @ts-ignore
            bankAccounts?.splice(index, 1);
        }
        
         
        console.log("userOrganisation.bankAccounts after delete: ", bankAccounts)
        setBankAccounts(bankAccounts);
        
    }
    
    
    useEffect(()=>{
        if(aftercreate){
            aftercreate(bankAccounts)
        }
    }, [bankAccounts])


    return <>

<Spin spinning={pageloading}>

    {(breakpoints.lg || breakpoints.xl) ?
        <Table
            locale={{
                triggerDesc: t('Kliknite_za_razvrščanje_padajoče'),
                triggerAsc: t('Kliknite_za_razvrščanje_naraščajoče'),
                cancelSort: t('Prekliči_razvrščanje'),
                emptyText: t('No_bank_accs')
            }}
            columns={columns}

            dataSource={pageloading ? [] : bankAccounts ? bankAccounts : undefined}
            loading={pageloading}
            scroll={{y: 'calc(37vh)'}}

            pagination={false}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        setClickedAccount(record)
                        setopenContractor(true)
                        //history.push("/products/"+record.integrationSpecificId)
                    }, // click row
                };
            }}
        /> :

        <div 
        //    style={{maxHeight: 'calc(67vh)', overflowY: "scroll"}}
        >
            <List
                loading={pageloading}

                dataSource={pageloading ? [] : bankAccounts ? bankAccounts : undefined}
                renderItem={(item) => (
                    <List.Item
                        // actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">more</a>]}
                    >
                        <List.Item.Meta
                            title={
                                <div
                                    onClick={(ev)=>{
                                        setClickedAccount(item)
                                        setopenContractor(true)
                                    }}
                                    //to={`/counterparts/editcontractor/${item.iban}`}
                                    > 
                                            <span >
                                                {item.iban ? item.iban : "-"}
                                            </span>
                                </div>

                            }
                            description={
                                <div>

                                    <Link
                                        style={{color: "#000"}}
                                        to={`/counterparts/editcontractor/${item.bic}`}> 
                                            <span>
                                                {item.bic}
                                            </span>
                                    </Link>
                                </div>
                            }
                        />

                    </List.Item>
                )}
            />
        </div>
        
        
    }
</Spin>
      
        <Button 
            style={{marginTop: "20px"}}
            onClick={()=>{
            setClickedAccount({position_number: "new", iban: "", bic: "", bankName: ""})
            setopenContractor(true)
        }}>{t("Add_new_bank_account")}</Button>



        <Drawer title={t("Edit_bank_account")} 
                placement="right" 
                onClose={()=>setopenContractor(false)} 
                open={openContractor}>
            <BankAccountDetailPage
                bankAccount={clickedAccount? clickedAccount : undefined} 
                aftercreate={setNewAccount}
                afterDelete = {(pos)=>{handleDelete(pos)}}
            
            
            />
        </Drawer>



    </>
    
}

export default BankAccountTable